<script setup lang="ts">
import { Card, Button } from "@/components/Ui";
import { onLink } from "@/helpers/events";
import gsap from "gsap";
import { onMounted, ref } from "vue";

// state
const localePath = useLocalePath();
const cardFirstImageEl = ref<HTMLElement | null>(null);

// methods

const onEnter = (el: HTMLElement | null) => {
  gsap.to(el, {
    x: 0,
    y: 0,
    lazy: true,
    duration: 1.5,
    ease: "power3",
  });
};

onMounted(() => {
  onEnter(cardFirstImageEl.value);
});
</script>
<template>
  <Card
    :title-class="`${$style[`title`]} ${$style[`title-${$i18n.locale}`]}`"
    :wrapper-class="$style[`root-wrapper`]"
    :action-class="$style[`root-action`]"
    :class="`${$style[`root`]}`"
  >
    <template #title>
      <span v-html="$t('block.privateQtyBins.title')" />
    </template>
    <div
      ref="cardFirstImageEl"
      :class="$style[`root-content`]"
    >
      <nuxt-img
        src="/img/block-qty-bins-v3.svg"
        aria-label="20+ BINs of cards in USD & EUR"
      />
      <Button
        :title="$t('btn.checkAllBins')"
        color="white"
        :class="$style[`root-button`]"
        @click="
          onLink('landing_privat_button-3', 'https://pulse.pst.net', {
            // 'cta type': 'button',
            // 'cta location': 'Block:20+ BINs of cards in USD & EUR',
            // 'cta text': 'Check out all BINs',
          })
        "
      />
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col gap-8 md:gap-0 md:flex-row justify-between;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[70px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  background-size: cover;
  background: linear-gradient(154.6deg, #306bd5 0%, #ff7d00 100%);

  &-wrapper {
    @apply h-full flex text-white relative z-10 md:justify-center;
  }

  &-content {
    @apply flex flex-col justify-center items-end;

    & img {
      @apply hidden md:block md:w-[220px] lg:w-[318px] mt-auto mb-8;
    }
  }
  &-button {
    @apply w-full md:w-[320px];
  }
}

.title {
  @apply font-black leading-[85%];
  @apply text-[58px] xxs:text-[72px] xs:text-[84px] md:text-[90px] lg:text-[134px] xl:text-[156px];
  &-pt,
  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-bg,
  &-it,
  &-ua,
  &-zh,
  &-es,
  &-th,
  &-pl,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw{
    @apply text-[58px] xxs:text-[72px] xs:text-[84px] md:text-[90px] lg:text-[134px] xl:text-[156px];
  }
  &-zh {
    @apply leading-[1.2];
  }
  &-tr {
    @apply text-[50px] xxs:text-[60px] xs:text-[72px] md:text-[76px] lg:text-[112px] xl:text-[142px];
  }
  &-de {
    @apply text-[68px] xxs:text-[82px] xs:text-[92px] md:text-[90px] lg:text-[122px] xl:text-[152px];
  }
  &-fr {
    @apply text-[54px] xxs:text-[66px] xs:text-[78px] md:text-[84px] lg:text-[120px] xl:text-[148px];
  }
  &-ru {
    @apply text-[72px] xxs:text-[86px] xs:text-[92px] md:text-[112px] lg:text-[154px] xl:text-[162px];
  }
  &-ja {
    @apply leading-[1.1];
    @apply text-[54px] xxs:text-[68px] xs:text-[82px] md:text-[86px] lg:text-[132px] xl:text-[146px];
  }
}
</style>
