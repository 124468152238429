<script setup lang="ts">
import { Card, Button } from "@/components/Ui";
import { onDashboard } from "@/helpers/events";
const { t } = useI18n();
</script>
<template>
  <Card
    :description-class="$style[`root-description`]"
    :wrapper-class="$style[`root-wrapper`]"
    :title-class="`${$style[`root-title`]} ${
      $style[`root-title-${$i18n.locale}`]
    }`"
    :action-class="$style[`root-action`]"
    :class="`${$style[`root`]}`"
  >
    <template #title>
      {{t("block.unlimitedCard.title")}}
    </template>
    <template #action>
      <Button
        :title="$t($i18n.locale === 'ru' ? 'btn.connect' : 'btn.learnMore')"
        :class="$style[`root-button`]"
        color="orange"
        @click="
          onDashboard('landing_main_button-3', '/register', {
            // 'cta type': 'button',
            // 'cta location': 'Block:Unlimited cards issue',
            // 'cta text': 'Learn more',
          })
        "
      />
    </template>
    <div :class="$style[`root-content`]">
      <nuxt-img
        src="/img/block-unlimited-cards-v2.svg"
        width="504"
        height="291"
        sizes="sm:100vw md:504px"
        aria-label="unlimited cards"
        loading="lazy"
        placeholder
      />
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[50px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-neutral-100;

  &-wrapper {
    @apply w-full relative z-10 mt-auto;
  }

  &-title {
    @apply text-neutral-800 md:mb-6 leading-[87%] font-bold;
    @apply text-[48px] xxs:text-[58px] xs:text-[68px] md:text-[62px] lg:text-[76px] xl:text-[98px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-it,
    &-pt,
    &-tr,
    &-zh,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[48px] xxs:text-[58px] xs:text-[68px] md:text-[62px] lg:text-[76px] xl:text-[98px];
    }
    &-ko,
    &-zh {
      @apply leading-[1.1];
    }
    &-ua {
      @apply text-[32px] xxs:text-[40px] xs:text-[48px] md:text-[62px] lg:text-[76px] xl:text-[98px];
    }
    &-de {
      @apply text-[28px] xxs:text-[36px] xs:text-[42px] md:text-[62px] lg:text-[76px] xl:text-[98px];
    }
    &-bg {
      @apply text-[34px] xxs:text-[42px] xs:text-[50px] md:text-[62px] lg:text-[76px] xl:text-[98px];
    }
    &-ru {
      @apply text-[34px] xxs:text-[42px] xs:text-[48px] md:text-[45px] lg:text-[59px] xl:text-[68px];
    }

    &-fr {
      @apply text-[40px] xxs:text-[48px] xs:text-[58px] md:text-[52px] lg:text-[68px] xl:text-[80px];
    }

    &-es {
      @apply text-[44px] xxs:text-[54px] xs:text-[64px] md:text-[58px] lg:text-[74px] xl:text-[90px];
    }

    &-ja {
      @apply leading-tight text-[44px] xxs:text-[56px] md:text-[52px] lg:text-[78px];
    }
  }

  &-button {
    @apply w-full lg:w-fit lg:min-w-[240px];
  }

  &-action {
    @apply mt-auto;
  }

  &-content {
    @apply absolute top-0 mt-8 w-[calc(100%-2rem)] lg:w-[calc(100%-4rem)] -translate-x-4 rtl:translate-x-4;
    & svg {
      @apply w-full;
    }
  }
}
</style>
