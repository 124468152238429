<script setup lang="ts">
import { Card, Button } from "@/components/Ui";
import { onEvent } from "@/helpers/events";
const localePath = useLocalePath();
</script>
<template>
  <Card
    :title-class="`${$style[`root-title`]} ${
      $style[`root-title-${$i18n.locale}`]
    }`"
    :description-class="$style[`root-description`]"
    :wrapper-class="$style[`root-wrapper`]"
    :action-class="$style[`root-action`]"
    :class="`${$style[`root`]}`"
  >
    <template #title>
      {{ $t("block.webmasterServices.title") }}
    </template>
    <template #action>
      <Button
        :title="$t('btn.learnMore')"
        color="white"
        class="w-full lg:w-fit lg:min-w-[240px]"
        @click="
          onEvent('landing_main_button-11',$router.push(localePath('/cards/ultima')),{
            // 'cta type': 'button',
            // 'cta location': 'Block: Maximize your shopping power',
            // 'cta text': 'Learn more',
          })
        "
      />
    </template>
    <div :class="$style[`root-content`]">
      <nuxt-img
        src="/img/block-webmaster-services.png"
        format="webp"
        width="970"
        height="329"
        sizes="sm:100vw md:50vw lg:970px"
        aria-label="unlock webmaster services"
        placeholder
        loading="lazy"
      />
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[50px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-accent-base;

  &-wrapper {
    @apply w-full max-w-[462px] relative z-10 h-full flex flex-col justify-end;
  }

  &-title {
    @apply leading-[1] text-white font-black mt-auto;
    @apply text-[30px] xxs:text-[38px] xs:text-[44px] md:text-[42px] lg:text-[56px] xl:text-[64px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-de,
    &-tr,
    &-ua,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[30px] xxs:text-[38px] xs:text-[44px] md:text-[42px] lg:text-[56px] xl:text-[64px];
    }
    &-ko {
      @apply leading-[1.2];
    }
    &-zh {
      @apply text-[28px] xxs:text-[34px] xs:text-[40px] md:text-[42px] lg:text-[56px] xl:text-[64px];
    }
    &-it {
      @apply text-[28px] xxs:text-[34px] xs:text-[40px] md:text-[42px] lg:text-[56px] xl:text-[64px];
    }
    &-bg {
      @apply text-[42px] xxs:text-[50px] xs:text-[58px] md:text-[53px] lg:text-[69px] xl:text-[79px];
    }
    &-ru {
      @apply text-[38px] xxs:text-[46px] xs:text-[48px] md:text-[50px] lg:text-[66px] xl:text-[79px];
    }
    &-pt {
      @apply text-[30px] xxs:text-[36px] xs:text-[42px] md:text-[44px] lg:text-[64px] xl:text-[68px];
    }
    &-fr {
      @apply text-[34px] xxs:text-[42px] lg:text-[58px] xl:text-[60px];
    }
    &-es {
      @apply text-[36px] xxs:text-[44px] xs:text-[42px] md:text-[40px] lg:text-[62px] xl:text-[72px];
    }
    &-ja {
      @apply leading-[1.1] text-[34px] xxs:text-[46px] xs:text-[44px] md:text-[42px] lg:text-[64px] xl:text-[68px];
    }
  }

  &-action {
    @apply lg:mt-8;
  }

  &-content {
    @apply absolute left-0 top-0 translate-x-[30px] translate-y-[50px] lg:translate-x-[50px] lg:translate-y-[70px];

    & img {
      @apply min-w-[400px] xxs:min-w-[550px] xs:min-w-[680px];
    }
  }
}
</style>
