<script setup lang="ts">
import { Section } from "@/components/Ui";
import BlockNoMoreRisk from "@/components/Homepage/Desktop/Blocks/BlockNoMoreRisk.vue";
import BlockTypeOfCards from "@/components/Homepage/Desktop/Blocks/BlockTypesOfCards.vue";

import { onMounted, onUnmounted, watch } from "vue";
import { isMobile } from "@/helpers/breakpoints";

interface Props {
  animate?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["on-complete"]);
const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);

onMounted(() => {
  if (gsap) {
    ctx.value = gsap.context(() => {
      // Title
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#home-second",
          pin: false,
          scrub: 0.1,
          end: "400px top"
          // markers: true
        }
      });
      tl
        .fromTo("#home-second .title", {
            y: "-55%",
            filter: "blur(4px)"
          },
          {
            y: 0,
            opacity: 1,
            duration: 2.5,
            filter: "blur(0px)",
            ease: "sine.out"
          }
        )
        .to("#home-second .title", {
          y: "240%",
          duration: 2.5,
          opacity: 0,
          filter: "blur(20px)",
          ease: "sine.out"
        }, ">");

      // Desktop - 1,2 block, mobile - 1 block
      const firstBlocks = isMobile.value
        ? ["#home-second .first"]
        : ["#home-second .first", "#home-second .second"];
      gsap.set(firstBlocks, { y: 500, opacity: 1 });
      firstBlocks.forEach((block: any, i: number) => {
        gsap.to(block, {
          y: 0,
          duration: 3,
          onLeave: () => emit("on-complete"),
          scrollTrigger: {
            trigger: block,
            // pin: true,
            // markers: true,
            scrub: 0.1,
            anticipatePin: 1,
            invalidateOnRefresh: true,
            immediateRender: false,
            start: i === 0 ? "-100% bottom" : "-30% bottom",
            end: i === 0 ? "-100px bottom" : "30% bottom",
            id: String(i)
          }
        });
      });

      // Desktop - null block, mobile - 2 block
      if (isMobile.value) {
        gsap.set("#home-second .second", { autoAlpha: 0, y: 50 });
        const animBlocks = gsap.to("#home-second .second", {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          paused: true,
          onLeave: () => emit("on-complete")
        });
        ScrollTrigger.create({
          trigger: "#home-second .second",
          end: "-200px top",
          once: true,
          // markers: true,
          scrub: 0.1,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          immediateRender: false,
          onEnter: (self) => {
            self.progress === 1 ? animBlocks.progress(1) : animBlocks.play();
          }
        });
      }
    });
  }
});
onUnmounted(() => {
  if (ctx.value) ctx.value.revert();
});
</script>
<template>
  <Section
    id="home-second"
    :title-class="`${$style[`root__title`]} ${
      $style[`root__title-${$i18n.locale}`]
    } title`"
    :class="$style.root"
  >
    <template #title>
      <span v-html="$t('section.madeForMarketing')" />
    </template>
    <div :class="`${$style[`root-wrapper`]}`">
      <BlockNoMoreRisk class="first" />
      <BlockTypeOfCards
        id="home-black-start"
        class="second"
      />
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto;

  &-wrapper {
    @apply w-full grid grid-cols-1 gap-[10px];
    @apply md:grid-cols-2;
  }

  &__title {
    @apply px-6 xxs:px-8 font-black text-left leading-[113%] w-full text-neutral-800 rtl:text-right;
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-tr,
    &-th,
    &-pl,
    &-ar,
    &-bn,
    &-ko,
    &-vi {
      @apply text-[48px] xxs:text-[56px] xs:text-[68px] md:text-[80px] lg:text-[110px] xl:text-[128px];
    }
    &-zh {
      @apply text-[48px] xxs:text-[56px] xs:text-[68px] md:text-[72px] lg:text-[102px] xl:text-[118px];
    }
    &-ua {
      @apply text-[48px] xxs:text-[56px] xs:text-[68px] md:text-[80px] lg:text-[96px] xl:text-[112px];
    }
    &-it {
      @apply text-[46px] xxs:text-[54px] xs:text-[66px] md:text-[78px] lg:text-[108px] xl:text-[124px];
    }
    &-fr {
      @apply text-[36px] xxs:text-[40px] xs:text-[48px] md:text-[62px] lg:text-[86px] xl:text-[100px];
    }
    &-hi,
    &-be,
    &-ru {
      @apply text-[34px] xxs:text-[38px] xs:text-[46px] md:text-[66px] lg:text-[90px] xl:text-[104px];
    }
    &-pt,
    &-de {
      @apply text-[48px] xxs:text-[56px] xs:text-[68px] md:text-[72px] lg:text-[100px] xl:text-[112px];
    }
    &-sw,
    &-bg,
    &-es {
      @apply text-[36px] xxs:text-[40px] xs:text-[48px] md:text-[60px] lg:text-[84px] xl:text-[96px];
    }
    &-ja {
      @apply text-[34px] xxs:text-[40px] xs:text-[46px] md:text-[56px] lg:text-[76px] xl:text-[88px];
    }
  }
}
</style>
