<script setup lang="ts">
import { Button } from "@/components/Ui";
import BlockFlip from "./BlockFlip.vue";
import { onDashboard } from "@/helpers/events";
import {isMobile} from "@/helpers/breakpoints";
import { useTextDirection } from "@vueuse/core";

interface Props {
  backOpenEvent?: string;
  backCloseEvent?: string;
  backButtonEvent?: string;
}

const props = defineProps<Props>();
const { t } = useI18n();

const img = useImage();
const backgroundStyles = computed(() => {
  const imgUrl = img(isMobile.value ? "/img/block-maximalize-power-v2.png" : "/img/block-maximalize-power-v2-desktop.png", { width: isMobile ? 1000 : 1100, format: "webp" });
  return { backgroundImage: `url('${imgUrl}')` };
});
const dir = useTextDirection();
</script>
<template>
  <BlockFlip
    :back-open-event="props.backOpenEvent"
    :back-close-event="props.backCloseEvent"
    color="semi-light"
    :block-class="$style.root"
    :block-style="backgroundStyles"
  >
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]">
      {{ t("block.buyAnywhere.title_1") }} <br />
      {{ t("block.buyAnywhere.title_2") }}
    </div>
    <template #back>
      <div :class="$style.modal">
        <div class="pb-10">
          <p>{{ t("block.buyAnywhere.modal_1") }}</p>
          <p>{{ t("block.buyAnywhere.modal_2") }}</p>
          <p>{{ t("block.buyAnywhere.modal_3") }}</p>
        </div>
        <Button
          :title="$t('btn.register')"
          color="orange"
          icon="arrow-outline-right"
          icon-pos="right"
          :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
          :class="$style.button"
          @click="onDashboard(
                String(props.backButtonEvent),
                '/register',
                {},
                {},
                true
              )"
        />
      </div>
    </template>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-neutral-800 flex flex-col justify-center gap-3 h-auto min-h-[580px] xxs:min-h-[668px];
  @apply bg-white;
  @apply bg-no-repeat bg-[50%_bottom] bg-[length:100%];

  @screen md {
    @apply h-full bg-[length:calc(100%-50px)] bg-[right_bottom];
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 17px 38px 0px rgba(0, 0, 0, 0.10), 0px 69px 69px 0px rgba(0, 0, 0, 0.09), 0px 155px 93px 0px rgba(0, 0, 0, 0.05), 0px 275px 110px 0px rgba(0, 0, 0, 0.01), 0px 430px 120px 0px rgba(0, 0, 0, 0.00);
  }
}

.title {
  @apply leading-none font-black pt-4 pb-2 mb-auto rtl:mr-auto;
  @apply md:pt-0;
  background: linear-gradient(154.6deg, #f7d15f 0%, #ff7d00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-bg,
  &-de,
  &-it,
  &-pt,
  &-tr,
  &-ua,
  &-zh,
  &-th,
  &-pl,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw{
    @apply text-[58px] xxs:text-[64px];
    @apply md:text-[86px];
  }
  &-ar {
    @apply pb-5;
  }
  &-ru {
    @apply text-[42px] xxs:text-[50px] xs:text-[58px];
    @apply md:text-[86px];
  }

  &-fr {
    @apply text-[58px] xxs:text-[64px];
    @apply md:text-[86px];
  }

  &-es {
    @apply text-[58px] xxs:text-[64px];
    @apply md:text-[74px] lg:text-[86px];
  }
  &-zh {
    @apply leading-[1.1];
  }
  &-ja {
    @apply text-[58px] xxs:text-[64px];
    @apply md:text-[86px];
  }
}

.modal {
  @apply relative flex flex-col h-full justify-center;
  & p {
    @apply text-neutral-800 text-[20px] pb-5;
    @apply md:text-[24px];
    @apply lg:text-[32px];
  }
}

.button {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:py-[17px] md:min-w-[465px] md:w-fit;
}
</style>