<script setup lang="ts">
import BlockFlip from "./BlockFlip.vue";

const { t } = useI18n();
</script>
<template>
  <BlockFlip :block-class="$style.root">
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]">
      {{ t("block.connectPayment.title_1") }}
      <br />
      {{ t("block.connectPayment.title_2") }}
      <span class="font-light">*</span>
    </div>
    <div :class="$style.content">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="67"
        height="66"
        fill="none"
        class="w-[62px] lg:w-[94px]"
        viewBox="0 0 67 66"
      >
        <path
          fill="#313438"
          d="M46.86.841c-1.4.44-2.374 2.029-2.174 3.563.106.734.2.948 1.307 3.03 1.414 2.628 3.042 6.564 3.949 9.54 2.64 8.62 3.094 17.361 1.347 26.289-1.027 5.23-2.922 10.435-5.455 14.945-.52.934-1 1.988-1.08 2.362-.255 1.174.093 2.309.973 3.122.747.72 1.267.921 2.374.921.854 0 1.094-.053 1.627-.36.894-.534 1.254-1.041 2.614-3.683 7.71-15.052 8.937-31.92 3.482-47.746-1.534-4.443-4.536-10.649-5.563-11.49-.826-.68-2.173-.866-3.4-.493Z"
        />
        <path
          fill="#313438"
          d="M34.575 7.22c-1.614.6-2.574 2.295-2.214 3.897.08.36.534 1.347 1.014 2.202 3.188 5.724 4.855 12.356 4.855 19.335 0 6.78-1.6 13.01-5.015 19.603-.84 1.615-.907 1.789-.894 2.576 0 .974.227 1.588.84 2.295.707.787 1.36 1.081 2.535 1.081 2 0 2.654-.694 4.815-5.004 3.36-6.712 4.975-14.171 4.735-21.885-.2-6.992-1.747-13.224-4.762-19.255-2.107-4.203-2.721-4.884-4.482-4.977-.586-.042-1.093.012-1.427.132Zm-12.217 6.205c-1.507.494-2.521 1.855-2.535 3.376 0 .64.08.921.534 1.735 2.895 5.257 4.002 9.18 4.002 14.118 0 4.937-1.108 8.86-4.002 14.117-.414.748-.534 1.121-.534 1.615 0 1.575 1.027 3.002 2.441 3.416.988.28 1.668.187 2.602-.333.92-.534 1.4-1.215 2.734-3.87 2.374-4.696 3.387-8.74 3.521-13.89.12-4.764-.44-8.247-2.067-12.73-.6-1.682-2.388-5.259-3.055-6.14-.827-1.08-2.588-1.76-3.641-1.414ZM9.7 19.79c-1.227.614-1.828 1.748-1.708 3.216.054.654.16 1 .467 1.481 2.134 3.256 2.614 4.778 2.614 8.167 0 3.39-.48 4.897-2.627 8.193-.387.6-.427.747-.427 1.748 0 .974.04 1.148.4 1.735.587.948 1.44 1.441 2.601 1.508 1.08.066 1.72-.12 2.361-.681.694-.614 1.707-2.188 2.654-4.15 1.427-2.963 1.774-4.35 1.88-7.42.08-2.401-.132-4.19-.773-6.485-.614-2.242-2.254-5.364-3.508-6.712-.933-1.014-2.6-1.267-3.934-.6Z"
        />
      </svg>

      <nuxt-img
        src="/img/block-connect-pay-v2.png"
        format="webp"
        width="400"
        height="333"
        sizes="sm:100vw md:50vw lg:550px"
        aria-label="Connect to Apple Pay & Google pay"
        loading="lazy"
        quality="100"
        class="md:w-full"
      />
    </div>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-neutral-800 flex flex-col justify-center gap-[100px] pb-0 h-auto;
  @apply md:h-full md:gap-10;
  @apply bg-neutral-100;
}

.title {
  @apply leading-[110%] font-black mb-auto;
  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-ru,
  &-fr,
  &-pt,
  &-tr,
  &-ja,
  &-th,
  &-pl,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi {
    @apply text-[46px] xxs:text-[52px] xs:text-[56px];
    @apply md:text-[50px];
    @apply lg:text-[64px];
  }
  &-sw {
    @apply text-[46px] xxs:text-[52px] xs:text-[56px];
    @apply md:text-[42px];
    @apply lg:text-[52px];
    @apply xl:text-[62px];
  }
  &-zh {
    @apply text-[46px] xxs:text-[52px] xs:text-[56px];
    @apply md:text-[42px];
    @apply lg:text-[52px];
    @apply xl:text-[64px];
  }
  &-it,
  &-de,
  &-bg {
    @apply text-[46px] xxs:text-[52px] xs:text-[56px];
    @apply md:text-[46px];
    @apply lg:text-[58px];
    @apply xl:text-[64px];
  }
  &-ua {
    @apply text-[40px];
    @apply md:text-[42px];
    @apply lg:text-[54px];
    @apply xl:text-[64px];
  }
  &-es {
    @apply text-[52px];
    @apply md:text-[50px];
    @apply lg:text-[64px];
  }
  &-ja {
    @apply leading-[1.3];
  }
}

.content {
  @apply flex flex-col gap-7 items-center;
}
</style>