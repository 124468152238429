<script setup lang="ts">
import { Hero } from "@/components/Ui";
import { useI18n } from "vue-i18n";
import HeroV2BlockSecondV2 from "~/components/Homepage/Desktop/Blocks/HeroV2BlockSecondV2.vue";
import HeroV2BlockFirstV2 from "~/components/Homepage/Desktop/Blocks/HeroV2BlockFirstV2.vue";

const { t } = useI18n();
</script>
<template>
  <Hero
    id="home-hero"
    :class="heroHome.root"
  >
    <div :class="heroHome.content">
      <h1 :class="[heroHome.title, heroHome[`title-${$i18n.locale}`]]" v-html="t('home.heroV2.title')" />

      <div :class="heroHome.wrapper">
        <HeroV2BlockFirstV2 class="z-10 md:z-0" />
        <HeroV2BlockSecondV2 class="z-20 md:z-0" />
      </div>
    </div>
  </Hero>
</template>
<style lang="scss" module="heroHome">
.root {
  @apply z-10 mx-auto pb-28 mb-48 md:pb-0 md:mb-0;
  @apply md:max-w-[1172px] md:pt-5 md:px-4 w-full;
}

.title {
  @apply text-center font-black lg:mb-12 xl:mb-10 text-neutral-800;
  @apply hidden md:block;


  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-tr,
  &-ua,
  &-bg,
  &-ru,
  &-fr,
  &-it,
  &-th,
  &-hi,
  &-ar,
  &-ko,
  &-vi,
  &-sw {
    @apply leading-none text-[60px] lg:text-[86px] xl:text-[90px];
  }
  &-bn {
    @apply leading-none text-[50px] lg:text-[68px] xl:text-[78px];
  }
  &-pl {
    @apply leading-none text-[50px] lg:text-[70px] xl:text-[80px];
  }
  &-es,
  &-pt {
    @apply leading-none text-[60px] lg:text-[80px] xl:text-[90px];
  }
  &-de {
    @apply leading-none text-[58px] lg:text-[78px] xl:text-[90px];
  }
  &-ja,
  &-zh {
    @apply text-[60px] lg:text-[76px];
    @apply leading-[1.2];
  }
}

.content {
  @apply flex flex-col items-center gap-10 h-full w-full flex-1;
}

.wrapper {
  @apply flex flex-col md:flex-row md:gap-[10px] w-full flex-1;
}
</style>
