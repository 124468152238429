<script setup lang="ts">
import { Section } from "@/components/Ui";
import { onMounted, onUnmounted } from "vue";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);

onMounted(() => {
  if (gsap) {
    ctx.value = gsap.context(() => {
      const titleEl = "#home-private-title .title";
      // Title
      gsap.set(titleEl, { autoAlpha: 0, y: 100 });
      gsap.to(titleEl, {
        autoAlpha: 1,
        duration: 3,
        y: 0,
        scrollTrigger: {
          trigger: titleEl,
          end: "200px bottom",
          // once: true,
          scrub: true,
          // markers: true,
          id: "title-show"
        }
      });
      gsap.set(titleEl, { y: 0 });
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#home-private-title",
          start: "300px bottom",
          end: "+=140%",
          // markers: true,
          scrub: true,
          pin: true,
          id: "title-scroll-blur"
          // pinSpacing: true,
        }
      });
      tl.to(titleEl, { yPercent: -160 });
      tl.to(titleEl, { scale: 2, filter: "blur(20px)", autoAlpha: 0 }, ">");
    });
  }
});
onUnmounted(() => {
  if (ctx.value) ctx.value.revert();
});
</script>

<template>
  <Section
    id="home-private-title"
    :class="$style.root"
  >
    <div class="wrapper">
      <div :class="`${$style[`root__title`]} title`">Private</div>
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full relative min-h-screen;

  &__title {
    @apply font-black text-[68px] xxs:text-[82px] sm:text-[140px] md:text-[170px] lg:text-[220px] xl:text-[250px] uppercase leading-none;
    background: linear-gradient(180deg, #ffffff 0%, #15191d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
</style>
