<script setup lang="ts">
import { Card, Button } from "@/components/Ui";
import { onEvent } from "@/helpers/events";

const localePath = useLocalePath();
</script>

<template>
  <Card
    :wrapper-class="$style[`root-wrapper`]"
    :title-class="`${$style[`root-title`]} ${
      $style[`root-title-${$i18n.locale}`]
    }`"
    :action-class="$style[`root-action`]"
    :class="`${$style[`root`]}`"
  >
    <template #title>
      <span v-html="$t('block.typesOfCard.title')" />
    </template>
    <template #action>
      <Button
        :title="$t('btn.learnMore')"
        color="white"
        :class="$style[`root-button`]"
        @click="
          onEvent('landing_main_button-5',$router.push(localePath('/private')),{
            // 'cta type': 'button',
            // 'cta location': 'Block:20+ type of cards in USD & EUR',
            // 'cta text': 'Learn more',
          })
        "
      />
    </template>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[50px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  background: linear-gradient(154.6deg, #306bd5 0%, #ff7d00 100%);

  &-wrapper {
    @apply w-full max-w-[462px] text-white relative z-10 h-full;
  }

  &-title {
    @apply leading-[85%] font-black;
    @apply text-[56px] xxs:text-[70px] xs:text-[82px] md:text-[76px] lg:text-[98px] xl:text-[114px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-pt,
    &-zh,
    &-pl,
    &-hi,
    &-ar,
    &-ko,
    &-th,
    &-vi,
    &-sw {
      @apply text-[56px] xxs:text-[70px] xs:text-[82px] md:text-[76px] lg:text-[98px] xl:text-[114px];
    }
    &-th {
      @apply leading-[1.05];
    }
    &-ko,
    &-hi,
    &-zh {
      @apply leading-[1.1];
    }
    &-ua {
      @apply text-[66px] xxs:text-[84px] xs:text-[94px] md:text-[76px] lg:text-[98px] xl:text-[114px];
    }
    &-tr {
      @apply text-[68px] xxs:text-[82px] xs:text-[96px] md:text-[76px] lg:text-[98px] xl:text-[114px];
    }
    &-it {
      @apply text-[70px] xxs:text-[86px] xs:text-[96px] md:text-[76px] lg:text-[98px] xl:text-[114px];
    }
    &-bn,
    &-de {
      @apply text-[62px] xxs:text-[74px] xs:text-[88px] md:text-[76px] lg:text-[98px] xl:text-[114px];
    }
    &-be,
    &-bg,
    &-ru {
      @apply text-[74px] xxs:text-[90px] xs:text-[96px] md:text-[100px] lg:text-[128px] xl:text-[138px];
    }

    &-fr {
      @apply text-[54px] xxs:text-[66px] xs:text-[78px] md:text-[72px] lg:text-[92px] xl:text-[110px];
    }

    &-es {
      @apply text-[58px] xxs:text-[72px] xs:text-[84px] md:text-[76px] lg:text-[98px] xl:text-[118px];
    }

    &-ja {
      @apply leading-[1.1] text-[56px] xxs:text-[70px] xs:text-[82px] md:text-[74px] lg:text-[98px] xl:text-[114px];
    }
  }

  &-button {
    @apply w-full lg:w-fit lg:min-w-[240px];
  }

  &-action {
    @apply mt-auto;
  }
}
</style>
