<script setup lang="ts">
import { Card } from "@/components/Ui";

const { t } = useI18n();
</script>
<template>
  <Card :class="`${$style[`root`]}`">
    <div :class="`${$style[`value`]}`">100</div>
    <div :class="`${$style[`title`]} ${$style[`title-${$i18n.locale}`]}`">
      {{ t("block.freePrivateCards.title_1") }}<br />{{ t("block.freePrivateCards.title_2") }}
    </div>
    <div :class="`${$style[`desc`]}`">
      {{ t("block.freePrivateCards.desc") }}
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col justify-start gap-7 lg:gap-10 xl:gap-[72px];
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[50px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-neutral-100;
}

.value {
  @apply font-semibold leading-[85%] tracking-[-17px] mt-24 mb-[44px];
  @apply text-[150px] xxs:text-[182px] xs:text-[200px] md:text-[186px] lg:text-[244px] xl:text-[262px];
  background: linear-gradient(
    94.04deg,
    #313438 14.07%,
    rgba(49, 52, 56, 0) 100.15%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.title {
  @apply text-neutral-800 font-bold;
  @apply leading-none;
  @apply text-[40px] xxs:text-[44px] md:text-[42px] lg:text-[58px];
  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-bg,
  &-it,
  &-pt,
  &-tr,
  &-zh,
  &-th,
  &-pl,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw{
    @apply text-[40px] xxs:text-[44px] md:text-[42px] lg:text-[58px];
  }
  &-zh {
    @apply leading-[1.2];
  }
  &-es {
    @apply text-[32px] xxs:text-[40px] 414:text-[44px] md:text-[42px] lg:text-[52px] xl:text-[58px];
  }
  &-ua {
    @apply text-[36px] xxs:text-[44px] md:text-[42px] lg:text-[58px];
  }
  &-de {
    @apply text-[38px] xxs:text-[44px] md:text-[42px] lg:text-[58px];
  }
  &-ru {
    @apply text-[40px] xxs:text-[44px] md:text-[42px] lg:text-[58px];
  }

  &-fr {
    @apply text-[40px] xxs:text-[44px] md:text-[42px] lg:text-[58px];
  }

  &-ja {
    @apply text-[40px] xxs:text-[44px] md:text-[42px] lg:text-[58px];
  }
}

.desc {
  @apply text-neutral-900 text-[14px] xxs:text-[18px] xs:text-[20px] lg:text-[24px] leading-snug;
}
</style>
