<script setup lang="ts">
import { Button} from "@/components/Ui";
import BlockFlip from "./BlockFlip.vue";
import { onDashboard } from "@/helpers/events";
import { useTextDirection } from "@vueuse/core";
interface Props {
  backOpenEvent?: string;
  backCloseEvent?: string;
  backButtonEvent?: string;
}

const props = defineProps<Props>();

const { t } = useI18n();
const dir = useTextDirection();
</script>
<template>
  <BlockFlip
    :back-open-event="props.backOpenEvent"
    :back-close-event="props.backCloseEvent"
    flip-direction="x"
    color="light"
    :block-class="$style.root"
  >
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]">
      {{ t("block.unlockFun.title") }}
    </div>
    <div :class="$style.content">
      <nuxt-img
        src="/img/services/apple-store.svg"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="apple store"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/airbnb.svg"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="airbnb"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/xbox.png"
        format="webp"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="xbox"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/netflix.png"
        format="webp"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="netflix"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/ps.svg"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="ps"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/uber.svg"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="uber"
        placeholder
        loading="lazy"
      />
    </div>
    <template #back>
      <div :class="$style.modal">
        <div class="pb-10">
          <p>{{t("block.unlockFun.modal_1")}}</p>
          <p>{{t("block.unlockFun.modal_2")}}</p>
        </div>
        <Button
          :title="$t('btn.register')"
          color="orange"
          icon="arrow-outline-right"
          icon-pos="right"
          :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
          :class="$style.button"
          @click="onDashboard(
                String(props.backButtonEvent),
                '/register',
                {},
                {},
                true
              )"
        />
      </div>
    </template>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-white flex flex-col justify-center gap-[42px];
  @apply md:gap-10;
  background: linear-gradient(330deg, rgba(255, 255, 255, 0) 36.6%, rgba(255, 255, 255, 0.1) 100%), #15191D;
}

.title {
  @apply leading-[108%] font-black;

  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-zh,
  &-th,
  &-hi,
  &-ar,
  &-ko,
  &-vi,
  &-sw {
    @apply text-[52px];
    @apply md:text-[56px];
    @apply lg:text-[72px];
    @apply xl:text-[80px];
  }
  &-pl, &-lv {
    @apply text-[42px];
    @apply lg:text-[56px];
    @apply xl:text-[66px];
  }
  &-bn,
  &-tr,
  &-pt,
  &-it,
  &-fr,
  &-es {
    @apply text-[48px];
    @apply md:text-[48px];
    @apply lg:text-[62px];
    @apply xl:text-[72px];
  }
  &-ua {
    @apply text-[48px];
    @apply md:text-[50px];
    @apply lg:text-[64px];
    @apply xl:text-[76px];
  }
  &-de {
    @apply text-[48px];
    @apply md:text-[50px];
    @apply lg:text-[68px];
    @apply xl:text-[80px];
  }
  &-bg {
    @apply text-[52px];
    @apply lg:text-[68px];
    @apply xl:text-[80px];
  }
  &-ru {
    @apply text-[50px] xxs:text-[52px];
    @apply md:text-[52px];
    @apply lg:text-[68px];
    @apply xl:text-[78px];
  }
  &-ja {
    @apply text-[42px];
    @apply md:text-[48px];
    @apply lg:text-[64px];
    @apply xl:text-[76px];
  }
  &-zh {
    @apply leading-[1.1];
  }
}

.content {
  @apply w-full grid grid-cols-3 gap-[10px];
  & > svg {
    @apply w-full h-full;
  }
}


.modal {
  @apply relative flex flex-col h-full justify-center;
  & p {
    @apply text-neutral-800 text-[18px] pb-5;
    @apply md:text-[20px];
    @apply lg:text-[26px];
    @apply xl:text-[28px];
  }
}

.button {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:py-[17px];
}
</style>