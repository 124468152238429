<script setup lang="ts">
import { Button } from "@/components/Ui";
import BlockFlip from "./BlockFlip.vue";
import { onDashboard } from "@/helpers/events";
import { useTextDirection } from "@vueuse/core";
interface Props {
  backOpenEvent?: string;
  backCloseEvent?: string;
  backButtonEvent?: string;
}
const props = defineProps<Props>();

const { t } = useI18n();
const dir = useTextDirection();
</script>
<template>
  <BlockFlip
    :back-open-event="props.backOpenEvent"
    :back-close-event="props.backCloseEvent"
    color="semi-light"
    flip-direction="x"
    :block-class="$style.root"
  >
    <nuxt-img
      src="/img/block-unlimited-cards-v2.png"
      sizes="sm:100vw md:504px"
      aria-label="unlimited cards"
      loading="lazy"
      placeholder
      class="max-w-full"
    />
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]">
      {{t("block.unlimitedCard.title")}}
    </div>
    <template #back>
      <div :class="$style.modal">
        <ul>
          <li>
            <p>{{t("block.unlimitedCard.modal_1")}}</p>
          </li>
          <li>
            <p>
              {{t("block.unlimitedCard.modal_2")}}
            </p>
          </li>
          <li>
            <p>
              {{t("block.unlimitedCard.modal_3")}}
            </p>
          </li>
        </ul>
        <Button
          :title="$t('btn.register')"
          color="orange"
          icon="arrow-outline-right"
          icon-pos="right"
          :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
          :class="$style.button"
          @click="onDashboard(
                String(props.backButtonEvent),
                '/register',
                {},
                {},
                true
              )"
        />
      </div>
    </template>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-neutral-800 flex flex-col justify-center gap-10;
  @apply bg-neutral-100;
}

.title {
  @apply leading-none font-black;

  &-en,
&-da,
&-lt,
&-nb,
&-ro,
&-fi,
&-sv,
&-he,
  &-it,
  &-fr,
  &-tr,
  &-th,
  &-ja,
  &-zh,
  &-th,
  &-hi,
  &-ar,
  &-ko {
    @apply text-[54px] xxs:text-[64px];
    @apply lg:text-[82px] xl:text-[96px];
  }
  &-sw, &-lv {
    @apply text-[54px] xxs:text-[60px];
    @apply lg:text-[76px] xl:text-[80px];
  }
  &-hu, &-sk, &-el {
    @apply text-[40px] xxs:text-[40px];
    @apply lg:text-[54px];
  }
  &-nb, &-id, &-nl {
    @apply text-[54px] xxs:text-[54px];
    @apply lg:text-[76px];
  }
  &-fi {
    @apply text-[50px] xxs:text-[50px];
    @apply lg:text-[60px];
  }
  &-cs {
    @apply text-[54px] xxs:text-[60px];
    @apply lg:text-[76px] xl:text-[90px];
  }
  &-sl, &-et {
    @apply text-[54px] xxs:text-[60px];
    @apply lg:text-[60px] xl:text-[76px];
  }
  &-vi,
  &-bn,
  &-ua {
    @apply text-[40px] xxs:text-[48px] xs:text-[56px];
    @apply md:text-[50px] lg:text-[66px] xl:text-[78px];
  }
  &-pl {
    @apply text-[40px] xxs:text-[48px] xs:text-[56px];
    @apply md:text-[40px] lg:text-[52px] xl:text-[62px];
  }
  &-de {
    @apply text-[40px] xxs:text-[48px] xs:text-[56px];
    @apply md:text-[40px] lg:text-[56px] xl:text-[64px];
  }
  &-pt,
  &-es {
    @apply text-[54px] xxs:text-[64px];
    @apply md:text-[58px] lg:text-[74px] xl:text-[88px];
  }
  &-bg {
    @apply text-[40px] xxs:text-[48px] xs:text-[56px];
    @apply md:text-[46px] lg:text-[60px] xl:text-[68px];
  }
  &-be,
  &-ru {
    @apply text-[40px] xxs:text-[48px] xs:text-[56px];
    @apply md:text-[44px] lg:text-[58px] xl:text-[68px];
  }
  &-zh,
  &-ja {
    @apply leading-[1.1];
  }
}

.modal {
  @apply relative flex flex-col h-full justify-center;

  & ul {
    @apply flex flex-col list-none ltr:pl-6 ltr:xxs:pl-8 ltr:xs:pl-10 rtl:pr-6 rtl:xxs:pr-8 rtl:xs:pr-10 pb-10;
    & li {
      @apply text-neutral-800 text-[20px] pl-2 xs:pl-4;
      @apply md:text-[24px] md:pb-5;
      @apply lg:text-[32px];
    }
  }

  & p {
    @apply text-neutral-800;
  }
}

.button {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:py-[17px];
}
</style>