<script setup lang="ts">
import { Section, Button } from "@/components/Ui";
import { onDashboard } from "@/helpers/events";
import {
  BlockTransparentModel,
  BlockFreeCardsV4,
  BlockOneDollarV2,
} from "@/components/Blocks";

import { onUnmounted, onMounted } from "vue";
import { isMobile } from "@/helpers/breakpoints";
import {getPrivateQueryParam} from "~/helpers/private";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const { t } = useI18n({
  useScope: "local",
});
const ctx = ref<any>(null);
const animation = () =>
  gsap.context(() => {
    // Title
    gsap.set("#private-best-terms .title", {
      y: "-200%",
      zIndex: -1,
      filter: "blur(8px)",
    });
    gsap.to("#private-best-terms .title", {
      y: 0,
      duration: 5,
      zIndex: 1,
      opacity: 1,
      filter: "blur(0px)",
      ease: "sine.out",
      scrollTrigger: {
        trigger: "#app",
        // pin: true,
        scrub: 0.1,
        start: "300px top",
        end: "700px top",
        // markers: true
      },
    });
    gsap.to("#private-best-terms .title", {
      duration: 5,
      filter: "blur(0px)",
      ease: "sine.out",
      scrollTrigger: {
        trigger: "#app",
        // pin: true,
        scrub: 0.1,
        start: "300px top",
        end: "500px top",
        // markers: true
      },
    });
    gsap.set("#private-best-terms .title", {
      y: "0",
      zIndex: -1,
      filter: "blur(0px)",
    });
    gsap.to("#private-best-terms .title", {
      y: "200%",
      // duration: 2.5,
      zIndex: -1,
      filter: "blur(20px)",
      scrollTrigger: {
        trigger: "#private-best-terms",
        // pin: true,
        scrub: 0.1,
        start: "top 100px",
        // markers: true
      },
    });

    // Desktop - 1,2 block, mobile - 1 block
    if (!isMobile.value) {
      const firstBlocks = [
        "#private-best-terms .second",
        "#private-best-terms .third",
      ];
      gsap.set(firstBlocks, { y: 500, opacity: 1 });
      firstBlocks.forEach((block: any, i: number) => {
        gsap.to(block, {
          y: 0,
          duration: 3,
          scrollTrigger: {
            trigger: block,
            // pin: true,
            // markers: true,
            scrub: 0.1,
            start: i === 0 ? "-100% bottom" : "-30% bottom",
            end: i === 0 ? "-100px bottom" : "30% bottom",
            id: String(i),
          },
        });
      });
    }

    // Desktop - null block, mobile - 2 block
    const toUpBlocks = isMobile.value
      ? [
          "#private-best-terms .first",
          "#private-best-terms .second",
          "#private-best-terms .third",
        ]
      : ["#private-best-terms .first"];
    gsap.set(toUpBlocks, { autoAlpha: 0, y: 50 });
    toUpBlocks.forEach((block: any, i: number) => {
      gsap.to(block, {
        autoAlpha: 1,
        duration: 3,
        y: 0,
        scrollTrigger: {
          trigger: block,
          end: "200px bottom",
          // once: true,
          scrub: 0.1,
          // markers: true,
        },
      });
    });
  });
onMounted(() => {
  setTimeout(() => {
    ctx.value = gsap.context(() => animation());
  }, 500);
});
onUnmounted(() => {
  ctx.value.revert();
});
</script>

<i18n lang="json">
{
  "en": {
    "title": "The best terms for media buying"
  },
  "ru": {
    "title": "Лучшие условия для медиабаинга"
  },
  "ja": {
    "title": "メディア購入の最良の条件"
  },
  "fr": {
    "title": "Les meilleures conditions d'achat média"
  },
  "es": {
    "title": "Las mejores condiciones para la compra de medios"
  }
}
</i18n>
<template>
  <Section
    id="private-best-terms"
    :title-class="`${$style[`root-title`]} ${
      $style[`root-title-${$i18n.locale}`]
    } title`"
    :class="$style.root"
  >
    <template #title>
      {{ t("title") }}
    </template>
    <div :class="`${$style[`root-wrapper`]}`">
      <BlockTransparentModel class="first" />
      <BlockFreeCardsV4 class="second" />
      <BlockOneDollarV2 class="third" />
    </div>
    <Button
      :title="$t('btn.joinToPstPriivate')"
      color="orange"
      :class="$style[`root-button`]"
      @click="
        onDashboard(
          'landing_privat_button-2',
          `/register?${getPrivateQueryParam()}`,
          {
            // 'cta type': 'button',
            // 'cta location': 'Hero:PST Private',
            // 'cta text': 'Join to PST Private',
          },
          {},
          true
        )
      "
    />
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto;

  &-wrapper {
    @apply w-full grid grid-cols-2 gap-[10px];
    & > div {
      @apply col-span-2 md:col-span-1;
    }

    & > div:first-child {
      @apply md:col-span-2;
    }
  }

  &-title {
    @apply px-6 xxs:px-8 font-light text-left leading-[113%] w-full text-white opacity-0 rtl:text-right;
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-bg,
    &-de,
    &-it,
    &-es,
    &-pt,
    &-tr,
    &-ua,
    &-ja,
    &-zh,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[34px] xxs:text-[40px] xs:text-[44px] md:text-[72px] lg:text-[100px];
    }

    &-ru {
      @apply text-[28px] xxs:text-[34px] xs:text-[40px] md:text-[82px] lg:text-[100px];
    }

    &-fr {
      @apply text-[34px] xxs:text-[40px] xs:text-[44px] md:text-[72px] lg:text-[100px];
    }

    &-ja {
      @apply text-[34px] xxs:text-[40px] xs:text-[44px] md:text-[72px] lg:text-[100px];
    }
  }

  &-button {
    @apply w-full max-w-[440px] mx-auto;
  }
}
</style>
