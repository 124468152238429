<script setup lang="ts">
import { Button, Card, Icon } from "@/components/Ui";
import { onEvent } from "@/helpers/events";
import { useI18n } from "vue-i18n";
const localePath = useLocalePath();
const { locale } = useI18n();
const { t } = useI18n();
</script>
<template>
  <Card
    :wrapper-class="$style[`root-wrapper`]"
    :description-class="`${$style[`root-description`]} ${
      $style[`root-description-${$i18n.locale}`]
    }`"
    :action-class="$style[`root-action`]"
    :class="`${$style[`root`]}`"
  >
    <template #description>
      {{ t("block.buyAnywhere.title_1") }} <br />
      {{ t("block.buyAnywhere.title_2") }}
    </template>
    <template #action>
      <Button
        :title="$t('btn.learnMore')"
        :class="$style[`root-button`]"
        @click="
          onEvent('landing_main_button-10',$router.push(localePath('/cards/ultima')),{
            // 'cta type': 'button',
            // 'cta location': 'Block: Maximize your shopping power',
            // 'cta text': 'Learn more',
          })
        "
      />
    </template>
    <div
      ref="cardFirstImageEl"
      :class="$style[`root-content`]"
    >
      <nuxt-img
        src="/img/block-maximalize-power.png"
        format="webp"
        sizes="sm:100vw md:720px lg:980px"
        width="1140"
        height="820"
        aria-label="Maximize your shopping power"
        loading="lazy"
        quality="90"
        placeholder
      />
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col;
  @apply px-[30px] pt-[50px] pb-[30px] md:py-[50px] lg:px-[50px] lg:pt-[90px] lg:pb-[90px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-white;
  box-shadow: 0px 275px 110px rgba(0, 0, 0, 0.01),
    0px 155px 93px rgba(0, 0, 0, 0.05), 0px 69px 69px rgba(0, 0, 0, 0.09),
    0px 17px 38px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

  &-wrapper {
    @apply h-full z-10 relative;
  }

  &-description {
    @apply leading-[100%] font-black py-2;
    background: linear-gradient(154.6deg, #f7d15f 0%, #ff7d00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @apply text-[46px] xxs:text-[58px] xs:text-[68px] md:text-[78px] lg:text-[108px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-de,
    &-pt,
    &-tr,
    &-zh,
    &-th,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[46px] xxs:text-[58px] xs:text-[68px] md:text-[78px] lg:text-[108px];
    }
    &-zh {
      @apply leading-[1.1];
    }
    &-it,
    &-bg {
      @apply text-[42px] xxs:text-[52px] xs:text-[62px] md:text-[78px] lg:text-[108px];
    }
    &-pl,
    &-be,
    &-ua,
    &-ru {
      @apply text-[32px] xxs:text-[42px] xs:text-[50px]  md:text-[78px] lg:text-[100px];
    }

    &-fr {
      @apply text-[48px] xxs:text-[56px] xs:text-[68px]  md:text-[78px] lg:text-[100px];
    }

    &-es {
      @apply text-[36px] xxs:text-[46px] xs:text-[54px]  md:text-[78px] lg:text-[100px];
    }

    &-ja {
      @apply text-[50px] xxs:text-[60px] xs:text-[70px]  md:text-[78px] lg:text-[100px];
    }
  }

  &-button {
    @apply w-full md:w-fit md:min-w-[340px];
  }

  &-action {
    @apply mt-auto md:mt-4;
  }

  &-content {
    @apply block z-0;

    & img {
      @apply w-full mt-auto absolute right-0 bottom-0 h-auto min-w-[460px] xxs:min-w-[540px];
      @apply translate-y-[10px] translate-x-[20px] xxs:translate-y-[10px] xxs:translate-x-[20px] xs:translate-x-0 translate-y-0;
    }
  }
}
</style>
