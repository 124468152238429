<script setup lang="ts">
import { Section } from "@/components/Ui";

import BlockRestfulApi from "@/components/Homepage/Desktop/Blocks/BlockRestfulApi.vue";
import BlockBinChecker from "@/components/Homepage/Desktop/Blocks/BlockBinChecker.vue";
import BlockTeamwork from "@/components/Homepage/Desktop/Blocks/BlockTeamwork.vue";
import BlockBusinessModelV2 from "@/components/Homepage/Desktop/Blocks/BlockBusinessModelV2.vue";

import { onMounted, onUnmounted } from "vue";
import { isMobile } from "@/helpers/breakpoints";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);

onMounted(() => {
  if (gsap) {
    ctx.value = gsap.context(() => {
      // Desktop - 1,2 block, mobile - 1 block
      const firstBlocks = isMobile.value
        ? ["#home-private-end .first"]
        : ["#home-private-end .first", "#home-private-end .second"];
      gsap.set(firstBlocks, { y: 500, opacity: 1 });
      firstBlocks.forEach((block: any, i: number) => {
        gsap.to(block, {
          y: 0,
          duration: 3,
          scrollTrigger: {
            trigger: block,
            // pin: true,
            // markers: true,
            scrub: 0.1,
            anticipatePin: 1,
            invalidateOnRefresh: true,
            immediateRender: false,
            start: i === 0 ? "-100% bottom" : "-30% bottom",
            end: i === 0 ? "-100px bottom" : "30% bottom",
            id: String(i)
          }
        });
      });

      // Desktop - 3 block, mobile - 2,3 block
      const fullBlocks = isMobile.value
        ? [
          "#home-private-end .second",
          "#home-private-end .third",
          "#home-private-end .four"
        ]
        : ["#home-private-end .third", "#home-private-end .four"];
      gsap.set(fullBlocks, { autoAlpha: 0, y: 50 });
      fullBlocks.forEach((block: any, i: number) => {
        gsap.to(block, {
          autoAlpha: 1,
          duration: 3,
          y: 0,
          scrollTrigger: {
            trigger: block,
            end: "200px bottom",
            // once: true,
            scrub: 0.1,
            // markers: true,
            id: String(i)
          }
        });
      });
    });
  }
});
onUnmounted(() => {
  if (ctx.value) ctx.value.revert();
});
</script>

<template>
  <Section
    id="home-private-end"
    :class="$style.root"
  >
    <div :class="`${$style[`root-wrapper`]}`">
      <BlockRestfulApi class="first" />
      <BlockBinChecker class="second" />
      <BlockTeamwork
        id="home-teamwork"
        class="third"
      />
      <BlockBusinessModelV2 class="four" />
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto;

  &-wrapper {
    @apply w-full grid grid-cols-1 gap-[10px];
    @apply md:grid-cols-2;

    & > div {
      @apply col-span-1;
      &:nth-last-child(-n + 2) {
        @apply md:col-span-2;
      }

      &:last-child {
        @apply mt-[10px];
      }
    }
  }
}
</style>
