<script setup lang="ts">
import { Button } from "@/components/Ui";
import BlockFlip from "./BlockFlip.vue";
import { onLink } from "@/helpers/events";
import { isMobile } from "~/helpers/breakpoints";
import { useTextDirection } from "@vueuse/core";

interface Props {
  contentButtonEvent?: string;
}

const props = defineProps<Props>();
const dir = useTextDirection();
</script>
<template>
  <BlockFlip color="dark" :block-class="$style.root">
    <div class="flex flex-col gap-3">
      <div :class="[$style.title]">
        PST Pulse
      </div>
      <div :class="[$style.subtitle, $style[`subtitle-${$i18n.locale}`]]" v-html="$t('block.binChecker.subtitle')" />
    </div>
    <div :class="$style.image">
      <nuxt-img
        src="/img/block-advanced-bins-v2.svg"
        width="565"
        height="248"
        sizes="sm:100vw md:565px"
        aria-label="advanced bin"
        placeholder
        loading="lazy"
      />
    </div>
    <Button
      :title="isMobile ? $t('btn.moreAboutPulse') : $t('btn.checkBin')"
      color="orange"
      icon="arrow-outline-right"
      :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
      icon-pos="right"
      :class="$style.button"
      @click="
          onLink(
            String(props.contentButtonEvent),
            'https://pulse.pst.net',
            {}
            )"
    />
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-white flex flex-col justify-center gap-10;
  @apply md:gap-[72px];
  @apply bg-neutral-800;
}

.image {
  @apply -ml-[60px] -mr-[60px];
  @apply md:mx-0 md:flex md:justify-center;
  & img {
    @apply md:min-w-[600px] lg:min-w-[812px];
  }
}

.title {
  @apply text-white font-black leading-[110%] text-[58px] xxs:text-[64px];
  @apply md:text-[62px];
  @apply lg:text-[80px];
}

.subtitle {
  @apply text-accent-primary text-[32px] leading-[110%];

  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-bg,
  &-de,
  &-it,
  &-es,
  &-fr,
  &-pt,
  &-tr,
  &-zh,
  &-ru,
  &-th,
  &-pl,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw{
    @apply text-[24px];
    @apply lg:text-[36px];
    @apply xl:text-[42px];
    & br {
      @apply md:hidden;
    }
  }
  &-ua {
    @apply text-[24px];
    @apply lg:text-[36px];
    @apply xl:text-[42px];
    & br {
      @apply hidden lg:block;
    }
  }
  &-ja {
    @apply text-[24px];
    @apply lg:text-[34px];
    @apply xl:text-[40px];

    & br {
      @apply md:hidden;
    }
  }
}


.button {
  @apply text-[18px] xxs:text-[20px] md:mt-auto;
}
</style>