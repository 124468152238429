<script setup lang="ts">
import { Section } from "@/components/Ui";
import BlockBuyAnywhere from "@/components/Homepage/Desktop/Blocks/BlockBuyAnywhere.vue";
import BlockUnlockService from "@/components/Homepage/Desktop/Blocks/BlockUnlockService.vue";
import BlockUnlockFun from "@/components/Homepage/Desktop/Blocks/BlockUnlockFun.vue";
import { onUnmounted, onMounted } from "vue";
import { isMobile } from "@/helpers/breakpoints";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);

onMounted(() => {
  if (gsap.context) {
    ctx.value = gsap.context(() => {
      // Title
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#home-maximize-power",
          pin: false,
          scrub: 0.1,
          end: "400px top"
          // markers: true
        }
      });
      tl.fromTo(
        "#home-maximize-power .title",
        {
          y: "-200%",
          filter: "blur(4px)"
        },
        {
          y: 0,
          duration: 2.5,
          filter: "blur(0px)",
          ease: "sine.out"
        }
      ).to(
        "#home-maximize-power .title",
        {
          y: "200%",
          duration: 2.5,
          opacity: 0,
          filter: "blur(20px)",
          ease: "sine.out"
        },
        ">"
      );

      // Desktop - 1,2 block, mobile - 1 block
      if (!isMobile.value) {
        const firstBlocks = [
          "#home-maximize-power .second",
          "#home-maximize-power .third"
        ];
        gsap.set(firstBlocks, { y: 500, opacity: 1 });
        firstBlocks.forEach((block: any, i: number) => {
          gsap.to(block, {
            y: 0,
            duration: 3,
            scrollTrigger: {
              trigger: block,
              // pin: true,
              // markers: true,
              scrub: 0.1,
              anticipatePin: 1,
              invalidateOnRefresh: true,
              immediateRender: false,
              start: i === 0 ? "-100% bottom" : "-30% bottom",
              end: i === 0 ? "-100px bottom" : "30% bottom",
              id: String(i)
            }
          });
        });
      }

      // Desktop - null block, mobile - 2 block
      const toUpBlocks = isMobile.value
        ? [
          "#home-maximize-power .first",
          "#home-maximize-power .second",
          "#home-maximize-power .third"
        ]
        : ["#home-maximize-power .first"];
      gsap.set(toUpBlocks, { autoAlpha: 0, y: 50 });
      toUpBlocks.forEach((block: any, i: number) => {
        gsap.to(block, {
          autoAlpha: 1,
          duration: 3,
          y: 0,
          scrollTrigger: {
            trigger: block,
            end: "200px bottom",
            // once: true,
            scrub: 0.1
            // markers: true,
          }
        });
      });
    });
  }
});
onUnmounted(() => {
  if (ctx.value) ctx.value.revert();
});
</script>
<template>
  <Section
    id="home-maximize-power"
    :title-class="`${$style[`root__title`]} ${
      $style[`root__title-${$i18n.locale}`]
    } title`"
    :class="$style.root"
  >
    <template #title>
      <span v-html="$t('block.maximizePower.title')" />
    </template>
    <div :class="`${$style[`root-wrapper`]}`">
      <BlockBuyAnywhere class="first" />
      <BlockUnlockService class="second" />
      <BlockUnlockFun class="third" />
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto;

  &-wrapper {
    @apply w-full grid grid-cols-2 gap-[10px];
    & > div {
      @apply col-span-2 md:col-span-1;
    }

    & > div:first-child {
      @apply md:col-span-2;
    }
  }

  &__title {
    @apply px-6 xxs:px-8 font-black text-left leading-[113%] w-full text-neutral-800 hidden md:block rtl:text-right;
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-bg,
    &-de,
    &-pt,
    &-zh,
    &-th,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw {
      @apply text-[50px] xxs:text-[60px] xs:text-[70px] md:text-[72px] lg:text-[100px];
    }
    &-tr {
      @apply text-[50px] xxs:text-[60px] xs:text-[70px] md:text-[66px] lg:text-[92px] xl:text-[100px];
    }
    &-ua {
      @apply text-[50px] xxs:text-[60px] xs:text-[70px] md:text-[84px] lg:text-[100px];
    }
    &-pl,
    &-ru {
      @apply text-[46px] xxs:text-[48px] xs:text-[58px] md:text-[108px] lg:text-[100px];
    }
    &-it {
      @apply text-[46px] xxs:text-[48px] xs:text-[58px] md:text-[62px] lg:text-[86px] xl:text-[100px];
    }
    &-es {
      @apply text-[46px] xxs:text-[48px] xs:text-[58px] md:text-[68px] lg:text-[92px] xl:text-[100px];
    }
    &-fr {
      @apply text-[44px] xxs:text-[52px] xs:text-[60px] md:text-[84px] lg:text-[100px];
    }

    &-ja {
      @apply text-[50px] xxs:text-[60px] xs:text-[70px] md:text-[72px] lg:text-[100px];
    }
  }
}
</style>
