<script setup lang="ts">
import * as HomePage from "@/components/Homepage/Desktop";
import {
  HomeFirst,
  HomeSecond,
  HomePrivateTitle,
  HomePrivateStart,
  HomePrivateEnd,
  // HomeUpscaleBrand,
  HomeMaximizePower,
  HomeChooseCard,
  HomeQualities,
  BaseAffiliate
} from "@/components/Homepage/Desktop/Sections";
import { ref, onMounted, onUnmounted } from "vue";


const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>();
onMounted(() => {
  if (gsap.context) {
    ctx.value = gsap.context(() => {
      gsap.to(".bg-dark", {
        background: "#15191d",
        display: "block",
        scrollTrigger: {
          trigger: "#home-private-title",
          pin: false,
          scrub: true,
          end: "500px bottom",
          markers: false
        }
      });
      gsap.to(".bg-light", {
        background: "#FFFFFF",
        display: "block",
        scrollTrigger: {
          trigger: "#home-teamwork",
          pin: false,
          scrub: true,
          end: "130% top",
          markers: false
        }
      });
    });
  }
});
onUnmounted(() => {
  if (ctx.value) ctx.value.revert();
});
</script>

<template>
  <div :class="$style.root">
    <div
      class="bg-dark"
      :class="$style.bgDynamic"
    />
    <div
      class="bg-light"
      :class="$style.bgDynamic"
    />

    <div :class="$style.hero" />
    <HomePage.BlockHero />
    <HomeFirst />
    <HomeSecond />
    <HomePrivateTitle />
    <HomePrivateStart class="z-40" />
    <HomePrivateEnd class="-mt-[56px] lg:-mt-[106px]" />
<!--    <HomeUpscaleBrand />-->
    <HomeMaximizePower />
    <HomeChooseCard />
    <HomeQualities class="z-[-1]" />
    <BaseAffiliate learn-more-event="landing_main_button-20" />
  </div>
</template>

<style lang="scss" module>
.root {
  @apply flex flex-col gap-[86px] lg:gap-[136px] mt-[70px] md:mt-[90px];
}

.hero {
  @apply absolute top-0 left-0 right-0 w-screen min-h-[1400px] z-[-1];
  //background: linear-gradient(180deg, #d4d4d4 0%, rgba(212, 212, 212, 0) 100%);

  @screen 1440 {
    @apply min-h-[1400px];
  }
  @screen 1920 {
    @apply min-h-[1520px];
  }
  @screen 2560 {
    @apply min-h-[2060px];
  }
}

.bgDynamic {
  @apply fixed top-0 bottom-0 left-0 right-0 h-full w-full z-[-1];
}
</style>