<script setup lang="ts">
import { Section, Button } from "@/components/Ui";
import { BlockPrivateCashback, BlockPrivateFreeCards } from "@/components/Blocks";
import { onEvent } from "@/helpers/events";

const localePath = useLocalePath();
const { t } = useI18n();

import { onMounted, onUnmounted } from "vue";
import { isMobile } from "@/helpers/breakpoints";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);
const animation = () =>
  gsap.context(() => {
    // Desktop - 1,2 block, mobile - 1 block
    const firstBlocks = isMobile.value
      ? ["#home-private-start .first"]
      : ["#home-private-start .first", "#home-private-start .second"];
    gsap.set(firstBlocks, { y: 300, opacity: 1 });
    firstBlocks.forEach((block: any, i: number) => {
      gsap.to(block, {
        y: 0,
        duration: 3,
        scrollTrigger: {
          trigger: block,
          // pin: true,
          // markers: true,
          scrub: 0.1,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          immediateRender: false,
          start: i === 0 ? "-100% bottom" : "-30% bottom",
          end: i === 0 ? "-100px bottom" : "30% bottom",
          id: String(i)
        }
      });
    });

    // Desktop - 3 block, mobile - 2,3 block
    const fullBlocks = isMobile.value
      ? [
        "#home-private-start .second"
      ]
      : [];
    gsap.set(fullBlocks, { autoAlpha: 0, y: 50 });
    fullBlocks.forEach((block: any, i: number) => {
      gsap.to(block, {
        autoAlpha: 1,
        duration: 3,
        y: 0,
        scrollTrigger: {
          trigger: block,
          end: "100px bottom",
          // once: true,
          scrub: 0.1,
          // markers: true,
          id: String(i)
        }
      });
    });
  });
onMounted(() => {
  setTimeout(() => {
    ctx.value = gsap.context(() => animation());
  }, 500);
});
onUnmounted(() => {
  ctx.value.revert();
});
</script>

<template>
  <Section
    id="home-private-start"
    :class="[$style.root, $style.section]"
  >
    <div :class="`${$style[`root-wrapper`]} block`">
      <div :class="$style.blocks">
        <BlockPrivateCashback class="first" :class="$style.block" />
        <BlockPrivateFreeCards class="second" :class="$style.block" />
      </div>
      <div class="px-[1.875rem] md:px-0">
        <Button
          ref="buttonEl"
          :title="t('btn.joinPrivate')"
          color="orange"
          :class="$style['button']"
          class="button"
          @click="
          onEvent('landing_main_button-6',$router.push(localePath('/private')),{
            // 'cta type': 'button',
            // 'cta location': 'Section: After for media buying teams',
            // 'cta text': 'Join PST Private',
          })
        "
        />
      </div>
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto mt-[-160px];
  &-wrapper {
    @apply w-full flex flex-col gap-8;
  }
}

.blocks {
  @apply grid grid-cols-1 md:grid-cols-2 md:gap-[10px];
}

.button {
  @apply w-full md:min-w-[300px] md:w-fit mx-auto;
}

.section {
  @apply px-0 md:px-4;
}

.block {
  @apply rounded-none md:rounded-[40px];
}
</style>
