<script setup lang="ts">
import { HomepageV0, HomepageDesktop } from "@/components/Homepage";
import { ScreenLoader } from "@/components/Ui";
import { useRoute, useRouter } from "vue-router";
import { onEvent } from "@/helpers/events";
import { onMounted, ref } from "vue";
import { useLayoutStore } from "@/store/layout";
import { isMobile } from "@/helpers/breakpoints";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const layoutStore = useLayoutStore();
onBeforeMount(() => {
  if (route.query["landing-version"]) {
    layoutStore.landingVersion = String(route.query["landing-version"]);
  }
  // if (route.query["hero-version"]) {
  //   layoutStore.heroVersion = String(route.query["hero-version"]);
  // }
});
onMounted(() => {
  onEvent("home page viewed", null, {
    "product name": "pst.net"
  });
  onEvent("page_opened_version_" + layoutStore.landingVersion);

  if (route.hash) {
    setTimeout(() => {
      router.push({ hash: route.hash });
    }, 600);
  }
});
useHead({
  htmlAttrs: {
    class: "homepage"
  }
});
definePageMeta({
  page: "homepage"
});
useSeoMeta({
  title: "PST | " + t("page.home.seo.title"),
  description: t("page.home.seo.description"),
  ogTitle: "PST | " + t("page.home.seo.title"),
  ogDescription: t("page.home.seo.description")
});
</script>
<template>
  <div>
      <HomepageDesktop v-if="!isMobile" />
      <HomepageV0 v-else />
  </div>
</template>
