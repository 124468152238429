<script setup lang="ts">
import { Button, Icon } from "@/components/Ui";
import BlockFlip from "./BlockFlip.vue";
import { onDashboard } from "@/helpers/events";
import { useTextDirection } from "@vueuse/core";

interface Props {
  backOpenEvent?: string;
  backCloseEvent?: string;
  backButtonEvent?: string;
  contentButtonEvent?: string;
}
const dir = useTextDirection();
const props = defineProps<Props>();
const { t } = useI18n();
</script>
<template>
  <BlockFlip
    :back-open-event="props.backOpenEvent"
    :back-close-event="props.backCloseEvent"
    mode="light"
    flip-direction="x"
    :block-class="$style.root"
  >
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]" v-html="t('block.soSimple.title')"/>
    <ul :class="$style[`list-${$i18n.locale}`]">
      <li
        class="cursor-pointer"
        @click="onDashboard(
                String(props.contentButtonEvent),
                '/register',
                {},
                {},
                true
              )">
        1. {{ t("block.soSimple.sign_up") }}
        <span :class="dir === 'ltr' ? '' : 'rotate-180'">
          <Icon name="arrow-outline-right" />
        </span>
      </li>
      <li>
        2. {{ t("block.soSimple.get_card") }}
      </li>
    </ul>
    <template #back>
      <div :class="$style.modal">
        <div class="pb-10">
          <p>{{ t("block.soSimple.modal_1") }}</p>
          <p>{{ t("block.soSimple.modal_2") }}</p>
        </div>
        <Button
          :title="$t('btn.register')"
          color="orange"
          icon="arrow-outline-right"
          icon-pos="right"
          :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
          :class="$style.button"
          @click="onDashboard(
                String(props.backButtonEvent),
                '/register',
                {
                  'cta type': 'button',
                  'cta location': 'Block: So Simple - Modal',
                  'cta text': 'Sign up',
                },
                {},
                true
              )"
        />
      </div>
    </template>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-white flex flex-col justify-center gap-10;
  background: linear-gradient(154.6deg, #F7D15F 0%, #FF7D00 100%);

  & ul {
    @apply flex flex-col gap-4;
    & li {
      @apply leading-none font-normal flex items-center gap-4;

      & span {
        @apply min-w-[48px] w-12 h-12 rounded-full bg-white bg-opacity-20 flex items-center justify-center;
        @apply translate-y-1;

        & svg {
          @apply w-6 h-6 min-w-[24px]
        }
      }
    }
  }
}

.title {
  @apply leading-[87%] font-black;
  &-en,
&-da,
&-et,
&-lt,
&-nb,
&-ro,
&-el,
&-nl,
&-sv,
&-he,
  &-be,
  &-es,
  &-fr,
  &-ua,
  &-ja,
  &-zh,
  &-th,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi {
    @apply text-[80px];
    @apply md:leading-none md:text-[86px];
    @apply lg:text-[96px];
  }
  &-sw {
    @apply text-[80px];
    @apply md:leading-none md:text-[76px];
    @apply lg:text-[96px];
  }
  &-cs, &-sl, &-id, &-hu, &-lv, &-sk {
    @apply text-[60px];
    @apply md:leading-none md:text-[60px];
    @apply lg:text-[76px];
  }
  &-fi {
    @apply text-[50px];
    @apply md:leading-none md:text-[50px];
    @apply lg:text-[60px];
  }
  &-pl,
  &-tr,
  &-pt,
  &-de {
    @apply text-[62px] xxs:text-[68px];
    @apply md:leading-none md:text-[78px];
    @apply lg:text-[96px];
  }
  &-it,
  &-bg {
    @apply text-[62px] xxs:text-[68px] xs:text-[80px];
    @apply md:leading-none md:text-[66px];
    @apply lg:text-[84px] xl:text-[96px];
  }
  &-ru {
    @apply text-[62px] xxs:text-[68px] xs:text-[80px];
    @apply md:leading-none md:text-[80px];
    @apply lg:text-[96px];
  }
  &-ja,
  &-zh {
    @apply leading-[1.1];
  }
}

.list {
  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-fr,
  &-pt,
  &-zh,
  &-th,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw {
    @apply text-[42px];
    @apply md:text-[46px];
    @apply lg:text-[52px];
  }
  &-tr,
  &-pt,
  &-it,
  &-es {
    @apply text-[42px];
    @apply md:text-[36px];
    @apply lg:text-[46px];
    @apply xl:text-[52px];
  }
  &-ja {
    @apply text-[32px] xxs:text-[38px] xs:text-[42px];
    @apply md:text-[34px];
    @apply lg:text-[46px] xl:text-[50px];
  }
  &-pl,
  &-ua {
    @apply text-[32px];
    @apply md:text-[32px];
    @apply lg:text-[40px];
    @apply xl:text-[48px];
  }
  &-de {
    @apply text-[32px] xxs:text-[38px] xs:text-[42px];
    @apply md:text-[34px];
    @apply lg:text-[50px] xl:text-[50px];
  }
  &-bg {
    @apply text-[32px] xxs:text-[38px] xs:text-[42px];
    @apply md:text-[34px];
    @apply lg:text-[52px];
  }
  &-ru {
    @apply text-[32px] xxs:text-[38px] xs:text-[42px];
    @apply md:text-[34px];
    @apply lg:text-[44px];
    @apply xl:text-[52px];
  }
  &-zh,
  &-ja {
    @apply leading-[1.1];
    & span {
      @apply -mt-3;
    }
  }
}

.modal {
  @apply relative flex flex-col h-full justify-center;
  & p {
    @apply text-neutral-800 text-[20px];
    @apply md:text-[24px] md:pb-5;
    @apply lg:text-[32px];
  }
}

.button {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:py-[17px];
}
</style>