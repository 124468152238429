<script setup lang="ts">
import { Section } from "@/components/Ui";
import BlockSoSimple from "@/components/Homepage/Desktop/Blocks/BlockSoSimple.vue";
import BlockUnlimitedCards from "@/components/Homepage/Desktop/Blocks/BlockUnlimitedCards.vue";
import { onUnmounted, onMounted } from "vue";
import { isMobile } from "@/helpers/breakpoints";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);
const animation = () =>
  gsap.context(() => {
    // Desktop - 1,2,4,5 block, mobile - 1 block
    const firstBlocks = isMobile.value
      ? ["#home-first .first"]
      : [
        "#home-first .first",
        "#home-first .second"
      ];
    gsap.set(firstBlocks, { y: 500, opacity: 1 });
    firstBlocks.forEach((block: any, i: number) => {
      gsap.to(block, {
        y: 0,
        duration: 3,
        scrollTrigger: {
          trigger: block,
          // pin: true,
          // markers: true,
          scrub: 0.1,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          immediateRender: false,
          start: i === 0 || i === 2 ? "-100% bottom" : "-30% bottom",
          end: i === 0 || i === 2 ? "-100px bottom" : "30% bottom",
          id: String(i),
        },
      });
    });
  });
onMounted(() => {
  setTimeout(() => {
    ctx.value = gsap.context(() => animation());
  }, 500);
});
onUnmounted(() => {
  ctx.value.revert();
});
onUnmounted(() => {
  if (ctx.value) ctx.value.revert();
});
</script>

<template>
  <Section
    id="home-first"
    :class="$style.root"
  >
    <div :class="`${$style['root-wrapper']}`">
      <BlockSoSimple class="first" />
      <BlockUnlimitedCards class="second" />
      <!--      <BlockCryptoAndMore class="third" />-->
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto;
  //@apply mt-[-400px] md:mt-[-500px];

  &-wrapper {
    @apply w-full grid grid-cols-1 gap-[10px];
    @apply md:grid-cols-2;

    & > div {
      @apply col-span-1;
      //@apply opacity-0;
      //&:last-child {
      //  @apply md:col-span-2;
      //}
    }
  }
}
</style>