<script setup lang="ts">
import { Section, Button } from "@/components/Ui";
import { onEvent } from "@/helpers/events";
import { BlockPrivateCashback, BlockPrivateFreeCards } from "@/components/Blocks";
import { onMounted, onUnmounted } from "vue";
import { isMobile } from "@/helpers/breakpoints";
const localePath = useLocalePath();
const emit = defineEmits(["on-complete"]);
const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);

onMounted(() => {
  if (gsap) {
    ctx.value = gsap.context(() => {
      // Desktop - 1,2 block, mobile - 1 block
      const firstBlocks = isMobile.value
        ? ["#home-private-start .first"]
        : ["#home-private-start .first", "#home-private-start .second"];
      gsap.set(firstBlocks, { y: 300, opacity: 1 });
      firstBlocks.forEach((block: any, i: number) => {
        gsap.to(block, {
          y: 0,
          duration: 3,
          onLeave: () => emit("on-complete"),
          scrollTrigger: {
            trigger: block,
            // pin: true,
            // markers: true,
            scrub: 0.1,
            anticipatePin: 1,
            invalidateOnRefresh: true,
            immediateRender: false,
            start: i === 0 ? "-100% bottom" : "-30% bottom",
            end: i === 0 ? "-100px bottom" : "30% bottom",
            id: String(i)
          }
        });
      });

      // Desktop - null block, mobile - 2 block
      if (isMobile.value) {
        gsap.set("#home-private-start .second", { autoAlpha: 0, y: 50 });
        const animBlocks = gsap.to("#home-second .second", {
          duration: 1,
          autoAlpha: 1,
          y: 0,
          paused: true,
          onLeave: () => emit("on-complete")
        });
        ScrollTrigger.create({
          trigger: "#home-private-start .second",
          end: "-100px top",
          once: true,
          // markers: true,
          scrub: 0.1,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          immediateRender: false,
          onEnter: (self) => {
            self.progress === 1 ? animBlocks.progress(1) : animBlocks.play();
          }
        });
      }
    });
  }
});
onUnmounted(() => {
  if (ctx.value) ctx.value.revert();
});
</script>

<template>
  <Section
    id="home-private-start"
    :class="$style.root"
  >
    <div :class="`${$style[`root-wrapper`]} block`">
      <div :class="$style.blocks">
        <BlockPrivateCashback class="first" />
        <BlockPrivateFreeCards class="second" />
      </div>
      <Button
        ref="buttonEl"
        :title="$t('btn.joinPrivate')"
        color="orange"
        :class="$style['button']"
        class="button"
        @click="
          onEvent('landing_main_button-6',$router.push(localePath('/private')),{})
        "
      />
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto mt-[-160px];
  &-wrapper {
    @apply w-full flex flex-col gap-8;
  }
}
.blocks {
  @apply grid grid-cols-1 md:grid-cols-2 gap-[10px];
}
.button {
  @apply w-full md:min-w-[300px] md:w-fit mx-auto;
}
</style>
