<script setup lang="ts">
import { Card, Icon, Button } from "@/components/Ui";
import { onEvent } from "@/helpers/events";

const localePath = useLocalePath();
</script>
<template>
  <Card
    :title-class="$style[`root-title`]"
    :description-class="$style[`root-description`]"
    :wrapper-class="$style[`root-wrapper`]"
    :action-class="$style[`root-action`]"
    :class="`${$style[`root`]}`"
  >
    <template #description>
      <span
        :class="`${$style[`root-subtitle`]} ${
          $style[`root-subtitle-${$i18n.locale}`]
        }`"
      >
        {{ $t("block.noMoreRisk.subtitle") }}
      </span>
      <div
        :class="`${$style[`root-title`]} ${
          $style[`root-title-${$i18n.locale}`]
        }`"
      >
        {{ $t("block.noMoreRisk.title1") }}<br />
        <span class="italic">{{ $t("block.noMoreRisk.title2") }}</span>
      </div>
      <Icon
        name="no-more-risk-social"
        path="pattern"
      />
    </template>
    <template #action>
      <Button
        :title="$t('btn.learnMore')"
        :class="$style[`root-button`]"
        @click="
          onEvent('landing_main_button-4',$router.push(localePath('/private')),{
            // 'cta type': 'button',
            // 'cta location': 'Block:No more risks',
            // 'cta text': 'Learn more',
          })
        "
      />
    </template>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[50px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-neutral-100;

  &-wrapper {
    @apply w-full h-full max-w-[462px] text-neutral-900 relative z-10;
  }

  &-title {
    @apply mb-20 font-black leading-[90%] py-4 text-neutral-800;
    @apply text-[54px] xxs:text-[67px] xs:text-[73px] md:text-[72px] lg:text-[86px];

    &-ru {
      @apply text-[52px] xxs:text-[64px] xs:text-[73px] md:text-[72px] lg:text-[86px];
    }

    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-fr,
    &-es,
    &-ua,
    &-zh,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[54px] xxs:text-[67px] xs:text-[73px] md:text-[72px] lg:text-[86px];
    }
    &-bn {
      @apply text-[48px] xxs:text-[58px] xs:text-[68px] md:text-[72px] lg:text-[86px];
      @apply leading-[1.2];
    }
    &-ko,
    &-zh {
      @apply leading-[1.1];
    }
    &-tr {
      @apply text-[46px] xxs:text-[57px] xs:text-[67px] md:text-[72px] lg:text-[86px];
    }
    &-de {
      @apply text-[42px] xxs:text-[52px] xs:text-[62px] md:text-[72px] lg:text-[86px];
    }
    &-pt {
      @apply text-[50px] xxs:text-[62px] xs:text-[74px] md:text-[72px] lg:text-[86px];
    }
    &-it,
    &-bg {
      @apply text-[44px] xxs:text-[54px] xs:text-[64px] md:text-[72px] lg:text-[86px];
    }
    &-ja {
      @apply leading-[1] text-[44px] xxs:text-[56px] xs:text-[64px] md:text-[60px] lg:text-[78px] xl:text-[86px];
    }
  }

  &-subtitle {
    @apply text-accent-primary leading-none font-medium;
    @apply text-[22px] xxs:text-[28px] xs:text-[32px] md:text-[30px] lg:text-[37px] xl:text-[47px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-bg,
    &-de,
    &-it,
    &-pt,
    &-tr,
    &-ua,
    &-zh,
    &-ru,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[22px] xxs:text-[28px] xs:text-[32px] md:text-[30px] lg:text-[37px] xl:text-[47px];
    }

    &-fr,
    &-es {
      @apply text-[18px] xxs:text-[22px] xs:text-[26px] md:text-[24px] lg:text-[30px] xl:text-[36px];
    }

    &-ja {
      @apply text-[22px] xxs:text-[28px] xs:text-[32px] md:text-[30px] lg:text-[37px] xl:text-[44px];
    }
  }

  &-description {
    @apply flex flex-col h-full mb-14;

    & svg {
      @apply w-full scale-[1.3];
    }
  }

  &-button {
    @apply w-full lg:w-fit lg:min-w-[240px];
  }

  &-action {
    @apply mt-auto;
  }
}
</style>
