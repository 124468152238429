<script setup lang="ts">
import { Button } from "@/components/Ui";
import BlockFlip from "./BlockFlip.vue";
import { onDashboard } from "@/helpers/events";
import { useTextDirection } from "@vueuse/core";

interface Props {
  backOpenEvent?: string;
  backCloseEvent?: string;
  backButtonEvent?: string;
}

const props = defineProps<Props>();

const { t } = useI18n();
const dir = useTextDirection();
</script>
<template>
  <BlockFlip
    :back-open-event="props.backOpenEvent"
    :back-close-event="props.backCloseEvent"
    color="light"
    flip-direction="x"
    :block-class="$style.root"
  >
    <div :class="$style.content">
      <nuxt-img
        src="/img/block-webmaster-services.png"
        format="webp"
        width="970"
        height="329"
        sizes="sm:100vw md:50vw lg:970px"
        aria-label="unlock webmaster services"
        placeholder
        loading="lazy"
      />
    </div>
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]">
      {{ $t("block.webmasterServices.title") }}
    </div>
    <template #back>
      <div :class="$style.modal">
        <div class="pb-10">
          <p>{{t("block.unlockService.modal_1")}}</p>
          <p>{{t("block.unlockService.modal_2")}}</p>
        </div>
        <Button
          :title="$t('btn.register')"
          color="orange"
          icon="arrow-outline-right"
          icon-pos="right"
          :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
          :class="$style.button"
          @click="onDashboard(
                String(props.backButtonEvent),
                '/register',
                {},
                {},
                true
              )"
        />
      </div>
    </template>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-white flex flex-col justify-start gap-10;
  @apply md:justify-center;
  @apply bg-accent-base;
}

.title {
  @apply leading-[108%] font-black;

  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-zh,
  &-th,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw{
    @apply text-[52px];
    @apply md:text-[56px];
    @apply lg:text-[72px];
    @apply xl:text-[80px];
  }
  &-pl {
    @apply text-[42px];
    @apply lg:text-[56px];
    @apply xl:text-[66px];
  }
  &-bn,
  &-tr,
  &-pt,
  &-it,
  &-fr,
  &-es {
    @apply text-[48px];
    @apply md:text-[48px];
    @apply lg:text-[62px];
    @apply xl:text-[72px];
  }
  &-ua {
    @apply text-[48px];
    @apply md:text-[50px];
    @apply lg:text-[64px];
    @apply xl:text-[76px];
  }
  &-de {
    @apply text-[48px];
    @apply md:text-[50px];
    @apply lg:text-[68px];
    @apply xl:text-[80px];
  }
  &-bg {
    @apply text-[52px];
    @apply lg:text-[68px];
    @apply xl:text-[80px];
  }
  &-ru {
    @apply text-[48px] xxs:text-[52px];
    @apply md:text-[52px];
    @apply lg:text-[66px];
    @apply xl:text-[78px];
  }
  &-zh {
    @apply leading-[1.1];
  }
  &-ja {
    @apply text-[42px];
    @apply md:text-[48px];
    @apply lg:text-[64px];
    @apply xl:text-[76px];
  }
}

.content {
  @apply pt-10;
  & img {
    @apply min-w-[528px] rounded-[8px];
  }
}


.modal {
  @apply relative flex flex-col h-full justify-center;
  & p {
    @apply text-neutral-800 text-[18px] pb-5;
    @apply md:text-[20px];
    @apply lg:text-[26px];
    @apply xl:text-[28px];
  }
}

.button {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:py-[17px];
}
</style>