<script setup lang="ts">
import { Button, Card } from "@/components/Ui";
import gsap from "gsap";
import { onMounted } from "vue";
import { onEvent } from "@/helpers/events";

const localePath = useLocalePath();
const cardFirstImageEl = ref<HTMLElement | null>(null);
const onEnter = (el: HTMLElement | null) => {
  gsap.to(el, {
    x: 0,
    y: 0,
    lazy: true,
    duration: 1.5,
    ease: "power3"
  });
};
onMounted(() => {
  onEnter(cardFirstImageEl.value);
});
</script>

<template>
  <Card
    :wrapper-class="$style[`root-wrapper`]"
    :action-class="$style[`root-action`]"
    :title-class="`${$style[`root-title`]} ${
      $style[`root-title-${$i18n.locale}`]
    }`"
    :class="`${$style[`root`]}`"
  >
    <template #title>
      <template v-if="$i18n.locale === 'ru'">
        Финансы команды <br /> на одном <br /> экране
      </template>
      <template v-else-if="$i18n.locale === 'be'">
        Фінансы каманды <br /> на адным <br /> экране
      </template>
      <template v-else>
        <span v-html="$t('block.teamWorkV2.title_1')" />
        <br />
        {{ $t("block.teamWorkV2.title_2") }}
      </template>
    </template>
    <template #action>
      <Button
        :title="$t('btn.learnMore')"
        :class="$style[`root-button`]"
        color="orange"
        @click="
          onEvent('landing_main_button-8',$router.push(localePath('/private')),{
            // 'cta type': 'button',
            // 'cta location': 'Block: Teamwork & financial control',
            // 'cta text': 'Learn more',
          })
        "
      />
    </template>
    <div
      ref="cardFirstImageEl"
      :class="$style[`root-content`]"
    >
      <nuxt-img
        src="/img/page-worldwide-card-teamwork.png"
        format="webp"
        width="1424"
        height="1178"
        sizes="sm:100vw md:50vw lg:1200px"
        aria-label="Teamwork & financial control"
        placeholder
        loading="lazy"
      />
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[70px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-white;

  &-wrapper {
    @apply w-full  h-full relative z-10;
  }

  &-button {
    @apply w-full md:w-fit md:min-w-[240px];
  }

  &-action {
    @apply mt-auto;
  }

  &-title {
    @apply leading-[91%] font-black text-neutral-800;
    @apply text-[44px] xxs:text-[55px] xs:text-[65px] md:text-[90px] lg:text-[105px] xl:text-[119px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-zh,
    &-hi,
    &-ar,
    &-bn,
    &-ko {
      @apply text-[44px] xxs:text-[55px] xs:text-[65px] md:text-[90px] lg:text-[105px] xl:text-[119px];
    }
    &-ko,
    &-zh {
      @apply leading-[1.1];
    }
    &-tr {
      @apply text-[38px] xxs:text-[44px] xs:text-[52px] md:text-[90px] lg:text-[105px] xl:text-[119px];
      @apply leading-[1.1];
    }
    &-de {
      @apply text-[26px] xxs:text-[32px] xs:text-[38px] md:text-[90px] lg:text-[105px] xl:text-[119px];
    }
    &-bg {
      @apply text-[36px] xxs:text-[46px] xs:text-[52px] md:text-[90px] lg:text-[105px] xl:text-[119px];
    }
    &-th,
    &-sw,
    &-pl,
    &-ua,
    &-ru {
      @apply text-[36px] xxs:text-[42px] xs:text-[46px] md:text-[72px] lg:text-[88px] xl:text-[98px];
    }
    &-vi,
    &-fr {
      @apply text-[40px] xxs:text-[48px] xs:text-[54px] md:text-[86px] lg:text-[105px] xl:text-[119px];
    }
    &-pt,
    &-it,
    &-es {
      @apply leading-none text-[36px] xxs:text-[44px] xs:text-[50px] md:text-[76px] lg:text-[100px] xl:text-[112px];
    }

    &-ja {
      @apply leading-none text-[38px] xxs:text-[46px] xs:text-[56px] md:text-[90px] lg:text-[105px] xl:text-[119px];
    }
  }

  &-content {
    @apply absolute right-0 left-0 top-0 bottom-0 h-full w-full flex items-center z-0 translate-x-[500px] translate-y-[500px];
    & img {
      @apply mt-auto transform transform-gpu -translate-x-[80px] translate-y-[20px] min-w-[1185px] h-auto  rtl:translate-x-[100px];

      @screen xxs {
        @apply translate-y-[80px];
      }

      @screen xs {
        @apply translate-y-[120px];
      }
      @screen md {
        @apply translate-x-[18%] translate-y-[12%];
      }

      @screen lg {
        @apply translate-x-[24%] translate-y-[22%];
      }
      @screen xl {
        @apply min-w-[1285px];
      }
    }
  }
}
</style>
