<script setup lang="ts">
import { Section, Button } from "@/components/Ui";
import {
  BlockCryptoAndMoreV2,
  BlockRestfulApiV3,
  BlockTeamWorkV3,
  BlockAdvancedBinsV2,
} from "@/components/Blocks";
import { onDashboard } from "@/helpers/events";
import { onMounted, onUnmounted } from "vue";
import { isMobile } from "@/helpers/breakpoints";
import {getPrivateQueryParam} from "~/helpers/private";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const { t } = useI18n({
  useScope: "local",
});
const ctx = ref<any>(null);
const animation = () =>
  gsap.context(() => {
    // Title
    gsap.set("#private-any-features .title", {
      y: "-200%",
      zIndex: -1,
      filter: "blur(8px)",
      autoAlpha: 0,
    });
    gsap.to("#private-any-features .title", {
      y: 0,
      duration: 5,
      zIndex: 1,
      autoAlpha: 1,
      ease: "sine.out",
      scrollTrigger: {
        trigger: "#private-any-features",
        // pin: true,
        scrub: 0.1,
        end: "-200px top",
        // markers: true
      },
    });
    gsap.to("#private-any-features .title", {
      duration: 5,
      filter: "blur(0px)",
      ease: "sine.out",
      scrollTrigger: {
        trigger: "#private-any-features",
        // pin: true,
        scrub: 0.1,
        end: "-400px top",
        // markers: true
      },
    });
    gsap.set("#private-any-features .title", {
      y: "0",
      zIndex: -1,
      filter: "blur(0px)",
      autoAlpha: 1,
    });
    gsap.to("#private-any-features .title", {
      y: "200%",
      // duration: 2.5,
      zIndex: -1,
      opacity: 0,
      filter: "blur(20px)",
      scrollTrigger: {
        trigger: "#private-any-features",
        // pin: true,
        scrub: 0.1,
        start: "top 100px",
        // markers: true
      },
    });

    // Desktop - 2, 3 block, mobile - 1 block
    const firstBlocks = isMobile.value
      ? ["#private-any-features .first"]
      : ["#private-any-features .second", "#private-any-features .third"];
    gsap.set(firstBlocks, { y: 500, opacity: 1 });
    firstBlocks.forEach((block: any, i: number) => {
      gsap.to(block, {
        y: 0,
        duration: 3,
        scrollTrigger: {
          trigger: block,
          // pin: true,
          // markers: true,
          scrub: 0.1,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          immediateRender: false,
          start: i === 0 || i === 2 ? "-100% bottom" : "-30% bottom",
          end: i === 0 || i === 2 ? "-100px bottom" : "30% bottom",
          id: String(i),
        },
      });
    });

    // Desktop - 3 block, mobile - 2,3 block
    const fullBlocks = isMobile.value
      ? [
          "#private-any-features .second",
          "#private-any-features .third",
          "#private-any-features .four",
        ]
      : ["#private-any-features .first", "#private-any-features .four"];
    gsap.set(fullBlocks, { autoAlpha: 0, y: 50 });
    fullBlocks.forEach((block: any, i: number) => {
      gsap.to(block, {
        autoAlpha: 1,
        duration: 3,
        y: 0,
        scrollTrigger: {
          trigger: block,
          end: "200px bottom",
          // once: true,
          scrub: 0.1,
          // markers: true,
          id: String(i),
        },
      });
    });
  });
onMounted(() => {
  setTimeout(() => {
    ctx.value = gsap.context(() => animation());
  }, 500);
});
onUnmounted(() => {
  ctx.value.revert();
});
</script>
<i18n lang="json">
{
  "en": {
    "title_1": "Any features",
    "title_2": "that make sense"
  },
  "ru": {
    "title_1": "Все необходимое",
    "title_2": "для результата"
  },
  "ja": {
    "title_1": "意味のある機能",
    "title_2": ""
  },
  "fr": {
    "title_1": "Toutes les fonctionnalités",
    "title_2": "qui ont du sens"
  },
  "es": {
    "title_1": "Cualquier característica",
    "title_2": "que tenga sentido"
  }
}
</i18n>
<template>
  <Section
    id="private-any-features"
    :title-class="`${$style[`root-title`]} ${
      $style[`root-title-${$i18n.locale}`]
    } title`"
    :class="$style.root"
  >
    <template #title>
      {{ t("title_1") }} <br />
      {{ t("title_2") }}
    </template>
    <div :class="`${$style[`root-wrapper`]}`">
      <BlockCryptoAndMoreV2 class="first" />
      <BlockRestfulApiV3 class="second" />
      <BlockTeamWorkV3 class="third" />
      <BlockAdvancedBinsV2 class="four" />
    </div>
    <Button
      :title="$t('btn.joinToPstPriivate')"
      color="orange"
      :class="$style[`root-button`]"
      @click="
        onDashboard(
          'landing_privat_button-5.2',
          `/register?${getPrivateQueryParam()}`,
          {
            // 'cta type': 'button',
            // 'cta location': 'Hero:PST Private',
            // 'cta text': 'Join to PST Private',
          },
          {},
          true
        )
      "
    />
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto;

  &-wrapper {
    @apply w-full grid grid-cols-1 gap-[10px];
    @apply md:grid-cols-2;

    & > div {
      @apply col-span-1;
      &:last-child,
      &:first-child {
        @apply md:col-span-2;
      }
    }
  }

  &-title {
    @apply px-6 xxs:px-8 font-light text-left leading-[113%] w-full text-white opacity-0 rtl:text-right;
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-bg,
    &-de,
    &-it,
    &-es,
    &-pt,
    &-tr,
    &-ua,
    &-zh,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[40px] xxs:text-[48px] xs:text-[56px] md:text-[72px] lg:text-[100px];
    }

    &-ru {
      @apply text-[34px] xxs:text-[38px] xs:text-[46px] md:text-[70px] lg:text-[100px];
    }

    &-fr {
      @apply text-[40px] xxs:text-[48px] xs:text-[56px] md:text-[72px] lg:text-[100px];
    }

    &-ja {
      @apply text-[40px] xxs:text-[48px] xs:text-[56px] md:text-[72px] lg:text-[100px];
    }
  }

  &-button {
    @apply w-full max-w-[440px] mx-auto;
  }
}
</style>
