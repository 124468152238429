<script setup lang="ts">
import { Section } from "@/components/Ui";
import Card from "@/components/Cards/Card.vue";
import { cards } from "@/config/cards";
import { onMounted, onUnmounted } from "vue";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);

const homeCards = computed(() => {
  return cards.sort(function(x, y) {
    return x.type === "private" ? -1 : y.type === "private" ? 1 : 0;
  });
});

const getCardByType = (type: string) => {
  return cards.find((card: { type: string }) => card.type === type);
};
const animation = () =>
  gsap.context(() => {
    // Title
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#home-choose-card",
        pin: false,
        scrub: 0.1,
        end: "400px top"
        // markers: true
      }
    });
    tl
      .fromTo("#home-choose-card .title", {
          y: "-200%",
          filter: "blur(4px)"
        },
        {
          y: 0,
          duration: 2.5,
          filter: "blur(0px)",
          ease: "sine.out"
        }
      )
      .to("#home-choose-card .title", {
        y: "200%",
        duration: 2.5,
        opacity: 0,
        filter: "blur(20px)",
        ease: "sine.out"
      }, ">");


    const cardTargets = gsap.utils.toArray(".card");
    gsap.set(cardTargets, { autoAlpha: 0, y: 50 });
    cardTargets.forEach((block: any, i: number) => {
      gsap.to(block, {
        autoAlpha: 1,
        duration: 3,
        y: 0,
        scrollTrigger: {
          trigger: block,
          end: "200px bottom",
          // once: true,
          scrub: 0.1,
          // markers: true,
          id: String(i)
        }
      });
    });
  });
onMounted(() => {
  setTimeout(() => {
    ctx.value = gsap.context(() => animation());
  }, 500);
});
onUnmounted(() => {
  ctx.value.revert();
});
</script>
<template>
  <Section
    id="home-choose-card"
    :title-class="`${$style[`root__title`]} ${
      $style[`root__title-${$i18n.locale}`]
    } title`"
    :class="[$style.root, $style.section]"
  >
    <template #title>
      <span v-html="$t('page.home.sectionChooseCardTitle_1')" />
      <template v-if="!['ru', 'be'].includes($i18n.locale)">
        {{ $t("page.home.sectionChooseCardTitle_2") }}
      </template>
    </template>
    <div
      id="pricing"
      class="w-0 h-0 absolute -translate-y-[200px]"
    />
    <div :class="`${$style[`root-wrapper`]}`">
      <Card
        :card="getCardByType('private')"
        class="card"
        get-card-event="landing_main_button-13.1"
        learn-more-event="landing_main_button-13.2"
        :class="$style.block"
      />
      <Card
        :card="getCardByType('ultima')"
        class="card"
        hide-conditions
        get-card-event="landing_main_button-14.1"
        learn-more-event="landing_main_button-14.2"
        :class="$style.block"
      />
      <Card
        :card="getCardByType('fb')"
        class="card"
        hide-conditions
        get-card-event="landing_main_button-15.1"
        learn-more-event="landing_main_button-15.2"
        :class="$style.block"
      />
      <Card
        :card="getCardByType('google')"
        class="card"
        hide-conditions
        get-card-event="landing_main_button-18.1"
        learn-more-event="landing_main_button-18.2"
        :class="$style.block"
      />
      <Card
        :card="getCardByType('tiktok')"
        class="card"
        hide-conditions
        get-card-event="landing_main_button-19.1"
        learn-more-event="landing_main_button-19.2"
        :class="$style.block"
      />
      <Card
        :card="getCardByType('adv')"
        class="card"
        hide-conditions
        get-card-event="landing_main_button-16.1"
        learn-more-event="landing_main_button-16.2"
        :class="$style.block"
      />
<!--      <Card-->
<!--        :card="getCardByType('platinum')"-->
<!--        class="card"-->
<!--        hide-conditions-->
<!--        get-card-event="landing_main_button-17.1"-->
<!--        learn-more-event="landing_main_button-17.2"-->
<!--        :class="$style.block"-->
<!--      />-->
<!--      <Card-->
<!--        :card="getCardByType('black')"-->
<!--        class="card"-->
<!--        hide-conditions-->
<!--        :class="$style.block"-->
<!--      />-->
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto z-10;

  &-wrapper {
    @apply w-full flex flex-col md:gap-[10px];
  }

  &__title {
    @apply px-6 xxs:px-8 font-black text-left leading-[113%] w-full text-neutral-800 rtl:text-right;
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-bg,
    &-it,
    &-pt,
    &-zh,
    &-es,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-sw{
      @apply text-[50px] xxs:text-[60px] xs:text-[70px] md:text-[108px] lg:text-[120px];
    }
    &-ua {
      @apply text-[38px] xxs:text-[44px] xs:text-[52px] md:text-[108px] lg:text-[120px];
    }
    &-tr {
      @apply text-[40px] xxs:text-[48px] xs:text-[56px] md:text-[108px] lg:text-[120px];
    }
    &-de {
      @apply text-[44px] xxs:text-[52px] xs:text-[60px] md:text-[108px] lg:text-[120px];
    }
    &-vi,
    &-ru {
      @apply text-[46px] xxs:text-[48px] xs:text-[58px] md:text-[82px] lg:text-[116px] xl:text-[120px];
    }

    &-fr {
      @apply text-[44px] xxs:text-[52px] xs:text-[60px] md:text-[104px] lg:text-[116px] xl:text-[100px];
    }

    &-ja {
      @apply text-[50px] xxs:text-[60px] xs:text-[70px] md:text-[108px] lg:text-[118px] xl:text-[116px];
    }
  }
}
.section {
  @apply px-0 md:px-4;
}
.block {
  @apply rounded-none md:rounded-[40px];
}
</style>
