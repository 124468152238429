<script setup lang="ts">
import { Section } from "@/components/Ui";
import BlockSecured from "@/components/Homepage/Desktop/Blocks/BlockSecured.vue";
import BlockConnectPay from "@/components/Homepage/Desktop/Blocks/BlockConnectPay.vue";
import BlockPlasticCard from "@/components/Homepage/Desktop/Blocks/BlockPlasticCard.vue";
import { onMounted, onUnmounted, watch } from "vue";
import { isMobile } from "@/helpers/breakpoints";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const { t } = useI18n();
const ctx = ref<any>(null);
onMounted(() => {
  if (gsap) {
    ctx.value = gsap.context(() => {
      // Title
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#home-qualities",
          pin: false,
          scrub: 0.1,
          end: "400px top"
          // markers: true
        }
      });
      tl.fromTo(
        "#home-qualities .title",
        {
          y: "-200%",
          filter: "blur(4px)"
        },
        {
          y: 0,
          duration: 2.5,
          filter: "blur(0px)",
          ease: "sine.out"
        }
      ).to(
        "#home-qualities .title",
        {
          y: "200%",
          duration: 2.5,
          opacity: 0,
          filter: "blur(20px)",
          ease: "sine.out"
        },
        ">"
      );

      // Desktop - 1,2 block, mobile - 1 block
      const firstBlocks = isMobile.value
        ? ["#home-qualities .first"]
        : ["#home-qualities .first", "#home-qualities .second"];
      gsap.set(firstBlocks, { y: 500, opacity: 1 });
      firstBlocks.forEach((block: any, i: number) => {
        gsap.to(block, {
          y: 0,
          duration: 3,
          scrollTrigger: {
            trigger: block,
            // pin: true,
            // markers: true,
            scrub: 0.1,
            anticipatePin: 1,
            invalidateOnRefresh: true,
            immediateRender: false,
            start: i === 0 ? "-100% bottom" : "-30% bottom",
            end: i === 0 ? "-100px bottom" : "30% bottom",
            id: String(i)
          }
        });
      });

      // Desktop - 3 block, mobile - 2,3 block
      const fullBlocks = isMobile.value
        ? ["#home-qualities .second", "#home-qualities .third"]
        : ["#home-qualities .third"];
      gsap.set(fullBlocks, { autoAlpha: 0, y: 50 });
      fullBlocks.forEach((block: any, i: number) => {
        gsap.to(block, {
          autoAlpha: 1,
          duration: 3,
          y: 0,
          scrollTrigger: {
            trigger: block,
            end: "200px bottom",
            // once: true,
            scrub: 0.1,
            // markers: true,
            id: String(i)
          }
        });
      });
    });
  }
});
onUnmounted(() => {
  if (ctx.value) ctx.value.revert();
});
</script>
<template>
  <Section
    id="home-qualities"
    :title-class="`${$style[`root__title`]} ${
      $style[`root__title-${$i18n.locale}`]
    } title`"
    :class="$style.root"
  >
    <template #title>
      <span v-html="t('section.qualities.title')" />
    </template>
    <div :class="`${$style[`root-wrapper`]}`">
      <BlockSecured class="first" />
      <BlockConnectPay class="second" />
      <BlockPlasticCard class="third" />
    </div>
    <div class="text-[15px] xxs:text-lg md:text-[20px] text-neutral-800 -translate-y-4">
      {{ $t("block.getPlasticCard.desc") }}
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto;

  &-wrapper {
    @apply w-full grid grid-cols-1 gap-[10px];
    @apply md:grid-cols-2;

    & > div {
      @apply col-span-1;
      &:last-child {
        @apply md:col-span-2;
      }
    }
  }

  &__title {
    @apply px-6 xxs:px-8 font-black text-left leading-[113%] w-full text-neutral-800 rtl:text-right;
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-bg,
    &-de,
    &-it,
    &-tr,
    &-ua,
    &-zh,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-sw {
      @apply text-[48px] xxs:text-[56px] xs:text-[68px] md:text-[80px] lg:text-[110px] xl:text-[100px];
    }
    &-fr {
      @apply text-[48px] xxs:text-[56px] xs:text-[68px] md:text-[80px] lg:text-[110px] xl:text-[100px];
    }

    &-ru {
      @apply text-[48px] xxs:text-[56px] xs:text-[68px] md:text-[76px] lg:text-[110px] xl:text-[100px];
    }
    &-vi,
    &-be,
    &-pt {
      @apply text-[46px] xxs:text-[48px] xs:text-[58px] md:text-[60px] lg:text-[82px] xl:text-[94px];
    }
    &-es {
      @apply text-[46px] xxs:text-[48px] xs:text-[58px] md:text-[62px] lg:text-[86px] xl:text-[100px];
    }

    &-ja {
      @apply text-[48px] xxs:text-[56px] xs:text-[68px] md:text-[80px] lg:text-[110px] xl:text-[100px];
    }
  }
}
</style>
