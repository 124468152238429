<script setup lang="ts">
import { Button } from "@/components/Ui";
import BlockFlip from "./BlockFlip.vue";
import { onDashboard } from "@/helpers/events";
import { isMobile } from "@/helpers/breakpoints";
import { useTextDirection } from "@vueuse/core";

interface Props {
  backOpenEvent?: string;
  backCloseEvent?: string;
  backButtonEvent?: string;
}

const props = defineProps<Props>();
const { t } = useI18n();
const dir = useTextDirection();
</script>
<template>
  <BlockFlip
    :back-open-event="props.backOpenEvent"
    :back-close-event="props.backCloseEvent"
    color="semi-light"
    flip-direction="y"
    :block-class="$style.root"
  >
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]">
      <template v-if="$i18n.locale === 'ru'">
        Финансы команды <br /> на одном <br /> экране
      </template>
      <template v-else-if="$i18n.locale === 'be'">
        Фінансы каманды <br /> на адным <br /> экране
      </template>
      <template v-else>
        <span v-html="$t('block.teamWorkV2.title_1')" />
        <br />
        {{ $t("block.teamWorkV2.title_2") }}
      </template>
    </div>
    <div :class="$style.content">
      <nuxt-img
        v-if="isMobile"
        src="/img/block-teamwork-home.png"
        format="webp"
        width="1104"
        height="1317"
        sizes="sm:800px md:50vw lg:1200px"
        aria-label="Teamwork & financial control"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        v-else
        src="/img/block-teamwork-home-desktop.png"
        format="webp"
        width="2826"
        height="1701"
        sizes="sm:800px md:50vw lg:2826px"
        aria-label="Teamwork & financial control"
        placeholder
        loading="lazy"
      />
    </div>
    <template #back>
      <div :class="$style.modal">
        <div class="pb-10">
          <p>{{ t("block.homeTeamwork.modal_1") }}</p>
          <p>{{ t("block.homeTeamwork.modal_2") }}</p>
          <p>{{ t("block.homeTeamwork.modal_3") }}</p>
        </div>
        <Button
          :title="$t('btn.register')"
          color="orange"
          icon="arrow-outline-right"
          icon-pos="right"
          :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
          :class="$style.button"
          @click="onDashboard(
                String(props.backButtonEvent),
                '/register',
                {},
                {},
                true
              )"
        />
      </div>
    </template>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply bg-white text-neutral-800 flex flex-col justify-start pb-0;
  @apply md:bg-neutral-100;
}

.title {
  @apply leading-[91%] font-black text-neutral-800 mb-auto pt-8;
  @apply md:leading-[110%];
  &-en,
&-id,
&-da,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-pt,
  &-tr,
  &-ua,
  &-zh,
  &-pl,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw {
    @apply text-[52px] xxs:text-[64px] xs:text-[72px];
    @apply md:text-[72px] md:w-2/3;
    @apply lg:text-[82px];
    @apply lg:w-1/2;
  }
  &-de, &-et{
    @apply text-[52px] xxs:text-[64px] xs:text-[72px];
    @apply md:text-[52px] md:w-2/3 md:mb-8;
    @apply lg:text-[68px] lg:mb-[6rem];
    @apply xl:text-[80px] xl:mb-[10rem];
  }
  &-th,
  &-bg {
    @apply text-[52px] xxs:text-[64px] xs:text-[72px];
    @apply md:text-[72px];
    @apply lg:w-2/3 xl:w-1/2;
  }
  &-ru {
    @apply text-[52px] xxs:text-[64px] xs:text-[72px];
    @apply md:text-[72px];
    @apply lg:text-[82px];
  }

  &-fr {
    @apply text-[52px] xxs:text-[64px] xs:text-[72px];
    @apply md:text-[72px];
    @apply lg:w-1/2 lg:text-[82px];
  }
  &-it,
  &-es {
    @apply text-[52px] xxs:text-[64px] xs:text-[72px];
    @apply md:text-[64px] md:w-2/3 md:mb-8;
    @apply lg:text-[68px];
    @apply xl:text-[80px];
  }

  &-ja {
    @apply text-[52px] xxs:text-[64px] xs:text-[72px];
    @apply md:text-[82px];
    @apply md:w-1/2;
  }
}

.content {
  @apply -translate-y-24;
  @apply md:-translate-y-[15%];
  & img {
    @apply min-w-[484px];
    @apply md:min-w-[1772px];
  }
}

.modal {
  @apply relative flex flex-col h-full justify-center;
  & p {
    @apply text-neutral-800 text-[20px] pb-5;
    @apply md:text-[24px];
    @apply lg:text-[32px];
  }
}

.button {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:py-[17px] md:max-w-[465px];
}
</style>