<script setup lang="ts">
import { Button, Icon } from "@/components/Ui";
import { onMounted, ref, watch } from "vue";
import { useWindowSize, useElementBounding, useTextDirection } from "@vueuse/core";
import { gsap } from "gsap";
import { onDashboard, onEvent } from "~/helpers/events";
import { getPrivateQueryParam } from "~/helpers/private";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const dir = useTextDirection();
const blockEl = ref<HTMLElement | null>(null);
const backdropEl = ref<HTMLElement | null>(null);
const wrapperEl = ref<HTMLElement | null>(null);
const frontEl = ref<HTMLElement | null>(null);
const backEl = ref<HTMLElement | null>(null);
const imageEl = ref<HTMLElement | null>(null);
const isFlipped = ref<boolean>(false);

const blockBounding = useElementBounding(blockEl);
const windowSize = useWindowSize();
const tl = gsap.timeline({ paused: true });

const initFlipCard = () => {
  gsap.set(wrapperEl.value, {
    transformStyle: "preserve-3d",
    transformPerspective: 1500
  });
  gsap.set(frontEl.value, {
    transformStyle: "preserve-3d",
    transformOrigin: "50% 50% 0"
  });

  gsap.set(backEl.value, {
    transformStyle: "preserve-3d",
    transformOrigin: "50% 50% 0"
  });

  const timing = 0.5;
  const backConfig = { rotationY: 180 };
  gsap.set(backEl.value, backConfig);

  const cardConfig = { rotationY: "+=180" };
  Object.assign(cardConfig, { duration: timing });
  tl.to(imageEl.value, { y: 100, duration: timing / 2, opacity: 0, scale: 0 }, 0);
  tl.to(frontEl.value, cardConfig, 0);
  tl.to(backEl.value, cardConfig, 0);
  tl.to(wrapperEl.value, { z: 0, duration: timing / 2, yoyo: true, yoyoEase: true, repeat: 1 }, 0);
  tl.to(backdropEl.value, { display: "block", duration: timing / 2, backgroundColor: "rgba(0,0,0,0.5)" }, 0);
  tl.to(blockEl.value, { zIndex: 1100, duration: timing / 2 }, 0);
};
const onFlipPlay = () => {
  tl.play();
  isFlipped.value = true;
};
const onFlipReverse = () => {
  tl.reverse();
  isFlipped.value = false;
};

onMounted(() => {
  if (gsap) {
    initFlipCard();
  }
});

watch(
  () => blockBounding.y.value,
  (newVal) => {
    if (
      windowSize.height.value - 180 < newVal ||
      blockBounding.height.value + newVal < 180
    ) {
      tl.reverse();
      isFlipped.value = false;
    }
  }
);
const isReady = ref(false);
onNuxtReady(() => {
  isReady.value = true;
});
</script>

<template>
  <div ref="blockEl" :class="block.root">
    <!--    <Teleport to="body">-->
    <div ref="backdropEl" :class="block.backdrop" @click="onFlipReverse" />
    <!--    </Teleport>-->
    <div ref="wrapperEl" :class="block.wrapper">
      <div
        ref="frontEl"
        :class="[block.content, block.front]"
      >
        <div
          :class="[block.open, block[`open-light`]]"
          @click="onFlipPlay"
        >
          <Icon name="add-outlined" />
        </div>
        <div class="h-full flex flex-col gap-7 justify-end">
          <div ref="imageEl" :class="block.image">
            <nuxt-img
              src="/img/hero-home-v2-card-2.webp"
              :aria-label="t('home.heroV2.title')"
              width="1761"
              height="1072"
              sizes="sm:800px md:50vw lg:900px"
            />
          </div>
          <div :class="[block.title, block[`title-${$i18n.locale}`]]">
            {{ t("home.heroV2.block2.title1", { p: 100 }) }}
            <br />
            {{ t("home.heroV2.block2.title2", { p: "3%" }) }}
          </div>
          <Button
            :title="t('btn.advCards')"
            color="orange"
            :class="block.button"
            @click="
                    onEvent('landing_main_hero-2',$router.push(localePath('/private')),{})
                    "
          />
        </div>
      </div>

      <div ref="backEl" v-show="isReady" :class="[block.content, block.back]">
        <span :class="block.close" @click="onFlipReverse">
          <Icon name="close-outline" />
        </span>
        <div :class="block.modal">
          <div class="pb-10">
            <p>{{ t("home.heroV2.block2.backText") }}</p>
          </div>
          <Button
            :title="t('btn.joinToPstPriivate')"
            color="orange"
            icon="arrow-outline-right"
            icon-pos="right"
            :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
            :class="block.backButton"
            @click="
          onDashboard(
            'landing_privat_button-1',
            `/register?${getPrivateQueryParam()}`,
            {},
            {},
            true
          )
        "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" module="block">
.root {
  @apply z-10 w-full mb-[-1px];
}

.backdrop {
  @apply hidden fixed top-0 left-0 bottom-0 right-0 z-10;
  transform: translateZ(0);
}

.wrapper {
  @apply relative z-20 w-full h-full min-h-full min-w-full;
  @apply min-h-[260px];
  @apply md:min-h-[420px] md:max-h-[440px] lg:min-h-[540px] lg:max-h-[600px];
}

.open {
  @apply hidden md:flex;
  @apply cursor-pointer items-center justify-center w-10 h-10 lg:w-12 lg:h-12 rounded-full overflow-hidden z-20;
  @apply absolute top-0 right-0 m-5;
  @apply transition-all hover:scale-[0.9];
  & svg {
    @apply w-5 h-5 lg:w-6 lg:h-6;
  }

  &-light {
    @apply text-white bg-white bg-opacity-30;
  }
}

.close {
  @apply flex items-center justify-center h-12 w-12 rounded-full bg-black bg-opacity-20 text-white;
  @apply transition-all focus:bg-black focus:bg-opacity-30 focus:outline-none;
  @apply hover:scale-[0.9];
  @apply absolute top-0 right-0 m-5;
  @apply cursor-pointer z-10;
  & svg {
    @apply w-6 h-6 min-w-[24px];
  }
}

.content {
  @apply md:absolute top-0 left-0 h-full w-full opacity-100;
  @apply px-[1.875rem] pt-5 lg:pt-10;
  @apply pb-16 md:pb-5 lg:pb-10;
  @apply md:rounded-[40px];
  backface-visibility: hidden;
}

.back {
  @apply bg-white hidden md:block;
}

.front {
  background: linear-gradient(180deg, #383838 0%, #222222 100%);
}

.image {
  @apply md:absolute w-full md:left-0 md:top-0 md:right-0 flex justify-center;
  @apply -mt-[50%] translate-x-[10%] -rotate-[2deg] md:translate-x-0 md:mt-0 md:-rotate-0 md:-translate-y-12 lg:-translate-y-12 -z-10;
  & img {
    @apply md:w-[calc(100%+40px)] lg:w-[calc(100%+80px)];
    @apply min-w-[164%] md:min-w-0;
  }
}

.title {
  @apply text-white font-black leading-[1.1];


  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-cs,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-pt,
  &-tr,
  &-ua,
  &-zh,
  &-de,
  &-bg,
  &-ru,
  &-it,
  &-es,
  &-th,
  &-pl,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi {
    @apply text-[44px] lg:text-[58px];
  }
  &-sw, &-el, &-sl {
    @apply text-[36px] xxs:text-[42px] md:text-[40px] lg:text-[52px] xs:text-[58px];
  }
  &-ja {
    @apply text-[44px] lg:text-[49px];
  }

  &-fr {
    @apply text-[44px] lg:text-[49px];
  }
}

.button {
  @apply w-full xl:max-w-[308px];
}

.modal {
  @apply relative flex flex-col h-full justify-center;
  & p {
    @apply text-neutral-800 text-[18px] pb-5;
    @apply md:text-[20px];
    @apply lg:text-[26px];
    @apply xl:text-[28px];
  }
}

.backButton {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:py-[17px];
}
</style>