<script setup lang="ts">
import { Card, Button } from "@/components/Ui";
import { onEvent } from "@/helpers/events";

const localePath = useLocalePath();
</script>
<template>
  <Card
    :title-class="`${$style[`root-title`]} ${
      $style[`root-title-${$i18n.locale}`]
    }`"
    :wrapper-class="$style[`root-wrapper`]"
    :class="`${$style[`root`]}`"
  >
    <template #title>
      {{ $t("block.unlockServices.title") }}
    </template>
    <div :class="$style[`root-content`]">
      <nuxt-img
        src="/img/services/apple-store.svg"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="apple store"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/airbnb.svg"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="airbnb"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/xbox.png"
        format="webp"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="xbox"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/netflix.png"
        format="webp"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="netflix"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/ps.svg"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="ps"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/services/uber.svg"
        width="143"
        height="140"
        sizes="sm:100vw md:143px"
        aria-label="uber"
        placeholder
        loading="lazy"
      />
    </div>
    <Button
      :title="$t('btn.learnMore')"
      color="white"
      class="w-full lg:w-fit lg:min-w-[240px]"
      @click="
          onEvent('landing_main_button-12',$router.push(localePath('/cards/ultima')),{
            // 'cta type': 'button',
            // 'cta location': 'Block: Maximize your shopping power',
            // 'cta text': 'Learn more',
          })
      "
    />
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col justify-between;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[50px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-neutral-900;

  &-wrapper {
    @apply w-full max-w-[462px] text-neutral-100 relative z-10;
  }

  &-title {
    @apply leading-[1] text-white font-black;
    @apply text-[30px] xxs:text-[38px] xs:text-[44px] md:text-[42px] lg:text-[56px] xl:text-[64px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-de,
    &-tr,
    &-ua,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[30px] xxs:text-[38px] xs:text-[44px] md:text-[42px] lg:text-[56px] xl:text-[64px];
    }
    &-ko {
      @apply leading-[1.2];
    }
    &-zh {
      @apply text-[28px] xxs:text-[34px] xs:text-[40px] md:text-[42px] lg:text-[56px] xl:text-[64px];
    }
    &-it {
      @apply text-[28px] xxs:text-[34px] xs:text-[40px] md:text-[42px] lg:text-[56px] xl:text-[64px];
    }
    &-bg {
      @apply text-[42px] xxs:text-[50px] xs:text-[58px] md:text-[53px] lg:text-[69px] xl:text-[79px];
    }
    &-ru {
      @apply text-[38px] xxs:text-[50px] xs:text-[58px] md:text-[53px] lg:text-[69px] xl:text-[79px];
    }
    &-pt {
      @apply text-[30px] xxs:text-[36px] xs:text-[42px] md:text-[44px] lg:text-[64px] xl:text-[68px];
    }
    &-fr {
      @apply text-[34px] xxs:text-[42px] xs:text-[46px] md:text-[44px] lg:text-[64px] xl:text-[68px];
    }
    &-es {
      @apply text-[36px] xxs:text-[44px] xs:text-[48px] md:text-[48px] lg:text-[62px] xl:text-[70px];
    }

    &-ja {
      @apply leading-[1.1] text-[34px] xxs:text-[46px] xs:text-[44px] md:text-[46px] lg:text-[64px] xl:text-[66px];
    }
  }

  &-content {
    @apply w-full grid grid-cols-3 gap-[10px];
    & > svg {
      @apply w-full h-full;
    }
  }
}
</style>
