<script setup lang="ts">
import { Section } from "@/components/Ui";
import {
  BlockRestfulApiV2,
  BlockAdvancedBins,
  BlockTeamWorkV2,
  BlockTransparentModel,
} from "@/components/Blocks";

import { onMounted, onUnmounted } from "vue";
import { isMobile } from "@/helpers/breakpoints";

const { $gsap: gsap, $ScrollTrigger: ScrollTrigger } = useNuxtApp();
const ctx = ref<any>(null);
const animation = () =>
  gsap.context(() => {
    // Desktop - 1,2 block, mobile - 1 block
    const firstBlocks = isMobile.value
      ? ["#home-private-end .first"]
      : ["#home-private-end .first", "#home-private-end .second"];
    gsap.set(firstBlocks, { y: 500, opacity: 1 });
    firstBlocks.forEach((block: any, i: number) => {
      gsap.to(block, {
        y: 0,
        duration: 3,
        scrollTrigger: {
          trigger: block,
          // pin: true,
          // markers: true,
          scrub: 0.1,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          immediateRender: false,
          start: i === 0 ? "-100% bottom" : "-30% bottom",
          end: i === 0 ? "-100px bottom" : "30% bottom",
          id: String(i),
        },
      });
    });

    // Desktop - 3 block, mobile - 2,3 block
    const fullBlocks = isMobile.value
      ? [
          "#home-private-end .second",
          "#home-private-end .third",
          "#home-private-end .four",
        ]
      : ["#home-private-end .third", "#home-private-end .four"];
    gsap.set(fullBlocks, { autoAlpha: 0, y: 50 });
    fullBlocks.forEach((block: any, i: number) => {
      gsap.to(block, {
        autoAlpha: 1,
        duration: 3,
        y: 0,
        scrollTrigger: {
          trigger: block,
          end: "200px bottom",
          // once: true,
          scrub: 0.1,
          // markers: true,
          id: String(i),
        },
      });
    });
  });
onMounted(() => {
  setTimeout(() => {
    ctx.value = gsap.context(() => animation());
  }, 500);
});
onUnmounted(() => {
  ctx.value.revert();
});
</script>

<template>
  <Section
    id="home-private-end"
    :class="[$style.root, $style.section]"
  >
    <div :class="`${$style[`root-wrapper`]}`">
      <BlockRestfulApiV2 class="first" :class="$style.block" />
      <BlockAdvancedBins class="second" :class="$style.block" />
      <BlockTeamWorkV2
        id="home-teamwork"
        class="third"
        :class="$style.block"
      />
      <BlockTransparentModel class="four" :class="$style.block" />
    </div>
  </Section>
</template>

<style lang="scss" module>
.root {
  @apply w-full h-auto;

  &-wrapper {
    @apply w-full grid grid-cols-1 md:gap-[10px];
    @apply md:grid-cols-2;

    & > div {
      @apply col-span-1;
      &:nth-last-child(-n + 2) {
        @apply md:col-span-2;
      }

      &:last-child {
        @apply md:mt-[10px];
      }
    }
  }
}
.section {
  @apply px-0 md:px-4;
}
.block {
  @apply rounded-none md:rounded-[40px];
}
</style>
